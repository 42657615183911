/* eslint-disable @typescript-eslint/naming-convention */
import textStyles from 'features/theme/foundations/textStyles';

const Table = {
  variants: {
    simple: (): Record<string, any> => ({
      th: {
        ...textStyles.heading3,
        color: 'primary.500',
        bgColor: 'bgLightQuartenary',
        textTransform: 'capitalized',
        borderBottom: '0',
      },
      td: {
        ...textStyles.body1,
        color: 'labelLightPrimary',
        bgColor: 'bgLightPrimary',
        borderColor: 'grey.500',
      },
    }),
  },
};

export default Table;
