import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const CircleTrash = createIcon({
  displayName: 'ConfettiIcon',
  viewBox: '0 0 56 56',
  defaultProps: {
    w: '56px',
    h: '56px',
    fill: 'none',
  },
  path: addKeys([
    <rect width="56" height="56" rx="28" fill="#F65D54" fillOpacity="0.2" />,
    <path
      d="M24.4375 20.3125H24.25C24.3531 20.3125 24.4375 20.2281 24.4375 20.125V20.3125H31.5625V20.125C31.5625 20.2281 31.6469 20.3125 31.75 20.3125H31.5625V22H33.25V20.125C33.25 19.2977 32.5773 18.625 31.75 18.625H24.25C23.4227 18.625 22.75 19.2977 22.75 20.125V22H24.4375V20.3125ZM36.25 22H19.75C19.3352 22 19 22.3352 19 22.75V23.5C19 23.6031 19.0844 23.6875 19.1875 23.6875H20.6031L21.182 35.9453C21.2195 36.7445 21.8805 37.375 22.6797 37.375H33.3203C34.1219 37.375 34.7805 36.7469 34.818 35.9453L35.3969 23.6875H36.8125C36.9156 23.6875 37 23.6031 37 23.5V22.75C37 22.3352 36.6648 22 36.25 22ZM33.1398 35.6875H22.8602L22.293 23.6875H33.707L33.1398 35.6875Z"
      fill="#F75D54"
    />,
  ]),
});
