import ENDPOINT from 'constants/endpoint';
import { IBaseModel, IRequestOption } from 'types/api';
import fetch from 'utils/fetch';
import { accessTokenStorage } from 'utils/storage';

import {
  IDeviceLocationModel,
  IDeviceLocationRequest,
} from './TrackerHomePage.types';

export async function getNodesLocation(
  params: IDeviceLocationRequest,
): Promise<IBaseModel<IDeviceLocationModel[]>> {
  const options: IRequestOption = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessTokenStorage.get().value}`,
    },
  };

  try {
    const response = await fetch<
      typeof params,
      IBaseModel<IDeviceLocationModel[]>
    >(ENDPOINT.DEVICES_LOCATION, options, params);

    if (response.code === 200) {
      return Promise.resolve(response);
    }

    throw new Error(response.message);
  } catch (error) {
    console.warn('status is not 200', error);
    return Promise.reject(error);
  }
}
