import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const Dropdown = createIcon({
  displayName: 'Dropdown',
  viewBox: '0 0 10 6',
  defaultProps: {
    w: '10px',
    h: '6px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M8.84861 0.515625H1.15173C0.920871 0.515625 0.791965 0.759375 0.934934 0.925781L4.78337 5.38828C4.89353 5.51602 5.10564 5.51602 5.21696 5.38828L9.0654 0.925781C9.20837 0.759375 9.07946 0.515625 8.84861 0.515625Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
  ]),
});
