import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Select,
  Spacer,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ExportFileWhite } from 'features/common/assets';
import { Calendar } from 'features/common/assets/Calendar';
import { DateTimePicker } from 'features/common/components/DateTimePicker';
import { IExportModalData } from 'features/dashboard/pages/AnalysisPage/AnalysisPage.types';
import { BarChartCard } from 'features/report/components/BarChart';
import { TableCard } from 'features/report/components/Table';
import { useLocation } from 'hooks/useData/useLocation';
import { useReport } from 'hooks/useData/useReport';
import useGlobalContext from 'hooks/useGlobalContext';
import useInterval from 'hooks/useInterval';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { mapDateTimeRange, mapFilterDateToRequestDate } from 'utils/helper';

export function ReportHomePage(): JSX.Element {
  const initialStartDate = moment().subtract(2, 'month').toDate();
  const initialEndDate = new Date();
  const [startDate, setStartDate] = useState(initialStartDate);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [endDate, setEndDate] = useState(initialEndDate);
  const [filterPlaceholder, setFilterPlaceholder] = useState(
    mapDateTimeRange(startDate, endDate),
  );

  const [requestDataParams, setRequestDataParams] = useState<any>(
    mapFilterDateToRequestDate(startDate, endDate),
  );

  const {
    isOpen: isFilterOpen,
    onOpen: onFilterOpen,
    onClose: onFilterClose,
  } = useDisclosure();

  const {
    state: {
      profile,
      report: { filter },
    },
  } = useGlobalContext();

  const isSwrPaused = () => {
    return isFilterOpen;
  };

  const { data: reports, mutate } = useReport(
    selectedLocation,
    {
      ...requestDataParams,
      time_zone: 'Asia/Jakarta',
    },
    {
      isPaused: isSwrPaused, // prevent rerender because of state change
    },
  );
  const { data: locations } = useLocation(profile.organization);

  async function filterData() {
    setRequestDataParams(mapFilterDateToRequestDate(startDate, endDate));
    setFilterPlaceholder(mapDateTimeRange(startDate, endDate));
  }

  useInterval(
    () => {
      if (isFilterOpen) return;
      if (!filter.isRelative || !filter.relativeProps) return;

      const { value, unit } = filter.relativeProps;

      const start = moment().subtract(value, unit).toDate();
      const end = new Date();
      setStartDate(start);
      setEndDate(end);
    },
    filter.isRelative ? 1000 : null,
  );

  useEffect(() => {
    if (!filter.isRelative) return;
    if (isFilterOpen) return;

    filterData();
  }, [startDate]);

  // effect to update swr config on hook state change
  useEffect(() => {
    if (!isFilterOpen) mutate();
  }, [isFilterOpen]);

  useEffect(() => {
    if (locations?.length > 0) setSelectedLocation(locations[0].device_code);
  }, [locations]);

  return (
    <Box minH="100vh" minW="full" pt="24" pl="28" pr="8" pb="10">
      <Grid templateColumns="repeat(4, 1fr)" gap={1}>
        <GridItem colSpan={3}>
          <Flex flexDirection="column">
            <Text textStyle="title" color="labelLightPrimary" mb="1.625rem">
              Report
            </Text>
          </Flex>
        </GridItem>
      </Grid>
      <Flex flexDirection="row">
        {locations?.length && (
          <Select
            size="md"
            width="15%"
            backgroundColor="#FFFFFF"
            onChange={(e) => setSelectedLocation(e.target.value)}
            value={selectedLocation}
          >
            {locations.map((location, i) => (
              <option key={i} value={location.device_code}>
                {location.name}
              </option>
            ))}
          </Select>
        )}
        <Spacer />
        <Flex gridGap={2}>
          <Button
            variant="secondary"
            bg="white"
            color="labelLightSecondary"
            leftIcon={<Calendar />}
            borderColor="gray.500"
            boxShadow="sm"
            onClick={onFilterOpen}
          >
            {filterPlaceholder}
          </Button>
          <Spacer />
          {/* <Button
            leftIcon={<ExportFileWhite />}
            textStyle="button"
            variant="default"
            onClick={() => console.warn('exportd')}
          >
            Export
          </Button> */}
        </Flex>
      </Flex>

      {reports && (
        <Grid
          templateColumns="repeat(2, 1fr)"
          gap={6}
          paddingTop={5}
          paddingRight={0}
        >
          <GridItem>
            <BarChartCard
              data={reports.filling_frequency}
              dataKey="date"
              dataKeyValue="frequency"
              title="Filling Frequency"
            />
          </GridItem>
          <GridItem>
            <BarChartCard
              data={reports.filling_histogram}
              dataKey="created_at"
              dataKeyValue="pressure_value"
              title="Filling Histogram"
            />
          </GridItem>
          <GridItem colSpan={1}>
            <TableCard
              data={reports.total_daily_usage}
              title="Daily Usage Report"
              heads={['Tanggal', 'Lokasi', 'Volume(m3)', 'Berat(kg)']}
            />
          </GridItem>
          <GridItem colSpan={1}>
            <TableCard
              data={reports.total_monthly_usage}
              title="Monthly Usage Report"
              heads={['Tanggal', 'Lokasi', 'Volume(m3)', 'Berat(kg)']}
            />
          </GridItem>
          <GridItem colSpan={2}>
            <TableCard
              data={reports.filling_report}
              title="Monthly Usage Report"
              heads={[
                'Tanggal Mulai',
                'Tanggal Selesai',
                'Lokasi',
                'Level awal (InH2O)',
                'Level akhir (InH2O)',
                'Total level (InH2O)',
                'Volume awal (m3)',
                'Volume akhir (m3)',
                'Total volume (m3)',
                'Berat awal (kg)',
                'Berat akhir (kg)',
                'Total berat (kg)',
              ]}
            />
          </GridItem>
        </Grid>
      )}
      <DateTimePicker
        isOpen={isFilterOpen}
        onClose={() => {
          onFilterClose();
        }}
        start={startDate}
        end={endDate}
        onStartChange={(date) => setStartDate(date)}
        onEndChange={(date) => setEndDate(date)}
        onApply={() => {
          filterData();
          onFilterClose();
        }}
      />
    </Box>
  );
}

export default ReportHomePage;
