import { Box, Flex } from '@chakra-ui/react';
import { ROUTE } from 'constants/route';
import { Header, Sidebar } from 'features/common/components';
import useGlobalContext from 'hooks/useGlobalContext';
import React, { useState } from 'react';
import { useFullScreenHandle } from 'react-full-screen';
import { useHistory } from 'react-router';
import { profileStorage } from 'utils/storage';

import { IPropTypes } from './DashboardPage.types';

export function DashboardPage(props: IPropTypes): JSX.Element {
  const { children } = props;
  const [activeLink, setActiveLink] = useState(ROUTE.DASHBOARD);
  const [hasNotification, setHasNotification] = useState(false);
  const history = useHistory();
  const {
    state: { profile },
  } = useGlobalContext();

  const handleMenuClick = (path: string) => {
    setActiveLink(path);
    history.push(path);
  };

  const handleOpenFullScreen = () => {
    useFullScreenHandle();
  };

  return (
    <Flex bgColor="bgLightSecondary">
      <Box position="fixed">
        <Sidebar
          onMenuClick={(path: string) => handleMenuClick(path)}
          activeLink={activeLink}
        />
      </Box>

      <Box flex={1}>
        <Header
          onFullScreen={handleOpenFullScreen}
          userData={profile ?? profileStorage.get()}
          hasNotification={hasNotification}
        />

        <Box minH="100vh" minW="full" pt="24" pl="28" pr="8" pb="10">
          {children}
        </Box>
      </Box>
    </Flex>
  );
}

export default DashboardPage;
