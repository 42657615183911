import { Box, Button, Flex, Image, Link, Text, VStack } from '@chakra-ui/react';
import { Wrapper } from 'features/auth/components';
import MailImage from 'features/common/assets/Mail.png';
import React from 'react';

import { IPropTypes } from './EmailVerifyPage.types';

export function EmailVerifyPage(props: IPropTypes): JSX.Element {
  // const { } = props;

  const renderLeftComponent = (): JSX.Element => (
    <VStack pt="8.125rem" pb={9} px="3.125rem" w="460px" textAlign="center">
      <Image src={MailImage} mb={12} />

      <Flex alignItems="center" flexDirection="column" px="1.5rem">
        <Text textStyle="subtitle" color="labelLightPrimary">
          Check your email
        </Text>

        <Box mb={4} />

        <Text textStyle="body1" color="labelLightSecondary" opacity="0.6">
          We have sent a password recover instruction to your email
        </Text>
      </Flex>

      <Box h="5.375rem" />

      <Text>
        Do not receive the email? Check your spam filter, or{' '}
        <Link href="/forgot-password" color="primary.500" textStyle="body1">
          try another email address
        </Link>
      </Text>
    </VStack>
  );

  return renderLeftComponent();
}

export default EmailVerifyPage;
