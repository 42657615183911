import { GlobalContext } from 'context/global';
import { Dispatch, useContext } from 'react';
import { IAction, IState } from 'types/context';

export default function useGlobalContext(): {
  state: IState;
  dispatch: Dispatch<IAction>;
} {
  return useContext(GlobalContext);
}
