import { Box, Flex, Grid, GridItem, Link, Text } from '@chakra-ui/react';
import { GaugeCharts, TotalUsageCard } from 'features/dashboard/components';
import { FillingCard } from 'features/dashboard/components/FillingCard';
import { gaugeMocks, usageMocks } from 'features/dashboard/mocks';
import { fillingMocks } from 'features/dashboard/mocks/filling';
import {
  EGaugeType,
  EPeriodeUsage,
  IUsageDataPeridically,
} from 'features/dashboard/types';
import { useNodesDetail } from 'hooks/useData/useNode';
import React from 'react';

interface IProps {
  deviceCode: string;
}

export function NodesDetailPage(props: IProps): JSX.Element {
  const { deviceCode } = props;
  const { data: nodesDetailData } = useNodesDetail(deviceCode, {
    time_zone: 'Asia/Jakarta',
  });

  const renderGaugeChart = (): JSX.Element => (
    <Link
      href={`/dashboard/nodes/${deviceCode}/analysis`}
      _hover={{ textDecor: 'none' }}
    >
      <GaugeCharts
        gaugeType={EGaugeType.type2}
        gaugeData={nodesDetailData.data?.device_detail_data ?? gaugeMocks}
      />
    </Link>
  );

  const renderUsageCards = (
    data: IUsageDataPeridically,
    periode: EPeriodeUsage,
  ): JSX.Element => (
    <Box>
      <TotalUsageCard usageData={data} periode={periode} />
    </Box>
  );

  return (
    <Box>
      <Flex flexDirection="column">
        <Text textStyle="title" color="labelLightPrimary" mb="1.625rem">
          Dashboard
        </Text>
        {nodesDetailData && (
          <Grid
            templateRows="repeat(2, 1fr)"
            templateColumns="repeat(4, 1fr)"
            gap={7}
          >
            <GridItem rowSpan={2} colSpan={2}>
              {nodesDetailData && renderGaugeChart()}
            </GridItem>
            <GridItem colSpan={1}>
              {renderUsageCards(
                nodesDetailData.data?.total_daily_usage ?? usageMocks,
                EPeriodeUsage.daily,
              )}
            </GridItem>
            <GridItem colSpan={1}>
              {renderUsageCards(
                nodesDetailData.data?.total_monthly_usage ?? usageMocks,
                EPeriodeUsage.monthly,
              )}
            </GridItem>
            <GridItem colSpan={2}>
              <Box>
                <FillingCard
                  fillingData={
                    nodesDetailData.data?.last_filling_data ?? fillingMocks
                  }
                />
              </Box>
            </GridItem>
          </Grid>
        )}
      </Flex>
    </Box>
  );
}

export default NodesDetailPage;
