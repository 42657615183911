import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const EyeOpen = createIcon({
  displayName: 'EyeOpen',
  viewBox: '0 0 24 24',
  defaultProps: {
    w: '24px',
    h: '24px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M22.0829 11.3953C19.8611 6.71484 16.5025 4.35938 12.0001 4.35938C7.49545 4.35938 4.1392 6.71484 1.91732 11.3977C1.8282 11.5864 1.78198 11.7925 1.78198 12.0012C1.78198 12.2099 1.8282 12.416 1.91732 12.6047C4.1392 17.2852 7.49779 19.6406 12.0001 19.6406C16.5048 19.6406 19.8611 17.2852 22.0829 12.6023C22.2634 12.2227 22.2634 11.782 22.0829 11.3953ZM12.0001 17.9531C8.21967 17.9531 5.4517 16.0359 3.49935 12C5.4517 7.96406 8.21967 6.04688 12.0001 6.04688C15.7806 6.04688 18.5486 7.96406 20.5009 12C18.5509 16.0359 15.7829 17.9531 12.0001 17.9531ZM11.9064 7.875C9.62826 7.875 7.78139 9.72188 7.78139 12C7.78139 14.2781 9.62826 16.125 11.9064 16.125C14.1845 16.125 16.0314 14.2781 16.0314 12C16.0314 9.72188 14.1845 7.875 11.9064 7.875ZM11.9064 14.625C10.4556 14.625 9.28139 13.4508 9.28139 12C9.28139 10.5492 10.4556 9.375 11.9064 9.375C13.3572 9.375 14.5314 10.5492 14.5314 12C14.5314 13.4508 13.3572 14.625 11.9064 14.625Z"
      fill="black"
    />,
  ]),
});
