/* eslint-disable @typescript-eslint/naming-convention */
import { extendTheme } from '@chakra-ui/react';

// components
import Alert from './components/Alert/Alert';
import Breadcrumb from './components/Breadcrumb/Breadcrumb';
import Button from './components/Button/Button';
import Card from './components/Card/Card';
import FormLabel from './components/FormLabel/FormLabel';
import Input from './components/Input/Input';
import Menu from './components/Menu/Menu';
import PinInput from './components/PinInput/PinInput';
import Table from './components/Table/Table';
// foundations
import colors from './foundations/colors';
import textStyles from './foundations/textStyles';

const overrides = {
  colors,
  textStyles,
  components: {
    Alert,
    Breadcrumb,
    Button,
    Card,
    FormLabel,
    Input,
    Menu,
    PinInput,
    Table,
  },
};

export default extendTheme(overrides);
