import ENDPOINT from 'constants/endpoint';
import { NodesGraphRequest } from 'features/dashboard/pages/AnalysisPage/AnalysisPage.types';
import { IDevice } from 'features/dashboard/pages/DashboardHomePage/DashboardHomePage.types';
import useSwr from 'swr';
import { IBaseModel, INodesDetail, IResponseApi } from 'types/api';
import { accessTokenStorage } from 'utils/storage';

import { IUsageDataPeridically } from '../../features/dashboard/types/usage';
import { createSwrKey, defaultOptions, fetcher } from './utils';

export function useNodes(
  organizationId: string,
  options = defaultOptions,
): IResponseApi<IBaseModel<IDevice[]>> {
  const { data, error, mutate } = useSwr<any, any>(
    createSwrKey(ENDPOINT.NODES, {
      params: { organization_id: organizationId },
    }),
    fetcher({
      method: 'GET',
      headers: { Authorization: `bearer ${accessTokenStorage.get().value}` },
    }),
    { ...options },
  );
  return {
    data: data,
    isLoading: false,
    error,
    mutate,
  };
}

export const useNodesDetail = (
  deviceCode: string,
  params: any,
  options = defaultOptions,
): IResponseApi<
  IBaseModel<
    INodesDetail<IDevice, IUsageDataPeridically, IUsageDataPeridically>
  >
> => {
  const { data, error, mutate } = useSwr<any, any>(
    createSwrKey(`${ENDPOINT.NODES_DETAIL}/${deviceCode}`, { params }),
    fetcher({
      method: 'GET',
      headers: { Authorization: `bearer ${accessTokenStorage.get().value}` },
    }),
    options,
  );
  return {
    data,
    isLoading: false,
    error,
    mutate,
  };
};

export function useNodesGraph(
  deviceCode: string,
  request: NodesGraphRequest,
  options = defaultOptions,
) {
  const { data, error, mutate } = useSwr<any, any>(
    createSwrKey(ENDPOINT.NODES_GRAPH(deviceCode), { params: request }),
    fetcher({
      method: 'GET',
      headers: { Authorization: `bearer ${accessTokenStorage.get().value}` },
    }),
    { ...options },
  );

  // TODO: sort data by date
  return { data, error, mutate };
}
