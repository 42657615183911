export const BASE_API =
  process.env.REACT_APP_BASE_URL || 'http://139.59.126.83:3333';

const ENDPOINT = {
  LOGIN: `${BASE_API}/api/user/login`,
  LOGOUT: `${BASE_API}/api/user/logout`,
  PROFILE: `${BASE_API}/api/user/profile`,
  ORGANIZATION: `${BASE_API}/api/organization`,
  NODES: `${BASE_API}/api/node-data/pressure-volume`,
  NODES_DETAIL: `${BASE_API}/api/node-data/pressure-volume/details`,
  DEVICES_LOCATION: BASE_API + '/api-v2/node-data/device-location',
  REPORT: `${BASE_API}/api/node-data/pressure-volume/report`,
  THRESHOLD: (deviceCode: string) =>
    `${BASE_API}/api/threshold-device/${deviceCode}`,
  NODES_GRAPH: (deviceCode: string) =>
    `${BASE_API}/api/node-data/pressure-volume/${deviceCode}`,
};

export default ENDPOINT;
