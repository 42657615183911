import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const ExportFile = createIcon({
  displayName: 'ConfettiIcon',
  viewBox: '0 0 25 25',
  defaultProps: {
    w: '25px',
    h: '25px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M21.3195 17.7516H20.0586C19.9602 17.7516 19.8781 17.8336 19.8781 17.932V19.3805H5.11953V4.61953H19.8805V6.06797C19.8805 6.16641 19.9625 6.24844 20.0609 6.24844H21.3219C21.4203 6.24844 21.5023 6.16875 21.5023 6.06797V3.71953C21.5023 3.32109 21.1813 3 20.7828 3H4.21953C3.82109 3 3.5 3.32109 3.5 3.71953V20.2805C3.5 20.6789 3.82109 21 4.21953 21H20.7805C21.1789 21 21.5 20.6789 21.5 20.2805V17.932C21.5 17.8312 21.418 17.7516 21.3195 17.7516ZM21.7555 11.8523L18.4297 9.22734C18.3055 9.12891 18.125 9.21797 18.125 9.375V11.1562H10.7656C10.6625 11.1562 10.5781 11.2406 10.5781 11.3438V12.6562C10.5781 12.7594 10.6625 12.8438 10.7656 12.8438H18.125V14.625C18.125 14.782 18.3078 14.8711 18.4297 14.7727L21.7555 12.1477C21.7779 12.1301 21.796 12.1077 21.8085 12.0821C21.8209 12.0565 21.8274 12.0285 21.8274 12C21.8274 11.9715 21.8209 11.9435 21.8085 11.9179C21.796 11.8923 21.7779 11.8699 21.7555 11.8523Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
  ]),
});
