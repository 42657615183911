import { Box, Button, Flex, Image, Link, Text } from '@chakra-ui/react';
import { BASE_API } from 'constants/endpoint';
import { ROUTE } from 'constants/route';
import { LoginForm, Wrapper } from 'features/auth/components';
import { ILogin } from 'features/auth/types';
import BgLogin from 'features/common/assets/BgLogin.png';
import LoginLogo from 'features/common/assets/telemetric-logo-blue.png';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import useGlobalContext from 'hooks/useGlobalContext';
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { ACTION_TYPES, ActionType } from 'types/context';
import { profileStorage } from 'utils/storage';

import { getMyProfile, postLogin } from './action';
// import { IPropTypes } from './LoginPage.types';

function prepareAvatarUrl(url: string) {
  // NOTE: backend should just send a full url to avoid bug in the frontend

  let avatarUrl = url;

  if (avatarUrl === '') return avatarUrl;

  if (!url.includes('http://') && !url.includes('https://')) {
    avatarUrl = BASE_API + avatarUrl;
  }

  return avatarUrl;
}

export function LoginPage(): JSX.Element {
  // const { } = props;
  const router = useHistory();
  const loginForm = useRef<FormikProps<ILogin>>(null);
  const {
    dispatch,
    state: { isLogin },
  } = useGlobalContext();

  useEffect(() => {
    if (isLogin) router.replace(ROUTE.DASHBOARD);
  }, []);

  const handleSubmit = async (
    values: ILogin,
    { setFieldError, setSubmitting }: FormikHelpers<ILogin>,
  ) => {
    try {
      setSubmitting(true);
      const loginData = await postLogin(values);
      const response = await getMyProfile();

      if (response.code !== 200 || !response.data)
        throw new Error(response.message);

      const {
        user: { email, profile, userRole },
        device_code,
      } = response.data;
      const { full_name: fullname, avatar_url, theme } = profile;
      const avatarUrl = prepareAvatarUrl(avatar_url ?? '');

      dispatch({
        type: ACTION_TYPES.SET_PROFILE as ActionType,
        payload: {
          avatarUrl,
          email,
          fullname,
          role: userRole.role,
          organization: loginData?.default_organization?.id ?? '',
          deviceCode: device_code,
        },
      });
      profileStorage.set({
        deviceCode: device_code,
        avatarUrl,
        email,
        fullname,
        role: userRole.role,
        organization: loginData?.default_organization?.id ?? '',
        theme,
      });

      router.push('/dashboard');
    } catch ({ message }) {
      const errStr = message as string;
      const isEmailError = errStr.toLowerCase().includes('email');
      setFieldError(isEmailError ? 'email' : 'password', errStr);
      setSubmitting(false);
    }
  };

  const renderLeftComponent = (): JSX.Element => (
    <Flex
      bgColor="bgLightPrimary"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      pt="3.75rem"
      pb="11.125rem"
      px="5.625rem"
    >
      <Image src={LoginLogo} mb="5.5rem" width={200} />

      <Text textStyle="title" mb={12}>
        Sign In
      </Text>

      <Formik
        innerRef={loginForm}
        initialValues={{ email: '', password: '' }}
        onSubmit={handleSubmit}
      >
        {({ isValid, isSubmitting, submitForm }) => {
          return (
            <Form>
              <LoginForm isLoading={false} />

              <Button
                type="submit"
                variant="default"
                onClick={submitForm}
                colorScheme="blue"
                isDisabled={!isValid}
                isLoading={isSubmitting}
              >
                Sign In
              </Button>
            </Form>
          );
        }}
      </Formik>

      <Box mb={7} />

      <Link textStyle="body1" color="labelLightPrimary" href="/forgot-password">
        Forgot Password?
      </Link>
    </Flex>
  );

  const renderRightComponent = (): JSX.Element => (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bgImage={BgLogin}
      bgRepeat="no-repeat"
      bgSize="cover"
      px="9.75rem"
    >
      <Text textStyle="title" color="bgLightSecondary">
        Welcome to Telemetric
      </Text>

      <Box mb={4} />

      <Text textStyle="heading1" color="bgLightSecondary">
        Don&apos;t get in the way of the data
      </Text>
    </Flex>
  );

  return (
    <Wrapper
      leftComponent={renderLeftComponent()}
      rightComponent={renderRightComponent()}
    />
  );
}

export default LoginPage;
