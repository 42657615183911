/* eslint-disable @typescript-eslint/naming-convention */
const Menu = {
  baseStyle: {
    list: {
      bgColor: 'bgLightSecondary',
      border: 'none',
      boxShadow:
        '0px 85px 179px rgba(29, 104, 184, 0.07), 0px 18.9859px 39.982px rgba(29, 104, 184, 0.0417275), 0px 5.65259px 11.9037px rgba(29, 104, 184, 0.0282725)',
    },
  },
};

export default Menu;
