/* eslint-disable react/jsx-key */
import { Box } from '@chakra-ui/react';
import { ROUTE } from 'constants/route';
import {
  AuthCodeEntryPage,
  CreateNewPasswordPage,
  EmailVerifyPage,
  ForgotPasswordPage,
  LoginPage,
} from 'features/auth/pages';
import ProtectedRoute from 'features/common/components/ProtectedRoute/ProtectedRouter.component';
import {
  AnalysisPage,
  DashboardHomePage,
  DashboardPage,
  NodesDetailPage,
} from 'features/dashboard/pages';
import { ReportPage } from 'features/report/pages';
import { TrackerPage } from 'features/tracker/pages';
import useGlobalContext from 'hooks/useGlobalContext';
import moment from 'moment';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { accessTokenStorage } from 'utils/storage';

export function App(): JSX.Element {
  const {
    state: { isLogin, profile },
  } = useGlobalContext();

  const { expire } = accessTokenStorage.get();

  const now = moment();
  const expireDate = moment(expire);
  const isExpired = now.isAfter(expireDate);
  const isAuthenticated = isLogin && !isExpired;

  const isRoleUser = profile.role === 'user';
  const renderMainPage = () => {
    if (isRoleUser) {
      return <NodesDetailPage deviceCode={profile.deviceCode} />;
    }

    return <DashboardHomePage />;
  };

  // TODO: fix routing for nested route i.e. dashboardPage, trackerPage
  return (
    <Box>
      <Switch>
        <Route exact path={ROUTE.ROOT}>
          <Redirect to={isAuthenticated ? ROUTE.DASHBOARD : ROUTE.LOGIN} />
        </Route>
        <Route exact path={ROUTE.LOGIN}>
          <LoginPage />
        </Route>
        <Route exact path="/forgot-password">
          <ForgotPasswordPage />
        </Route>
        <Route exact path="/verify-email">
          <EmailVerifyPage />
        </Route>
        <Route exact path="/auth-entry">
          <AuthCodeEntryPage />
        </Route>
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path="/create-new-password"
          component={CreateNewPasswordPage}
        />
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path={ROUTE.ASSET_TRACKER}
          component={TrackerPage}
        />
        <ProtectedRoute
          isAuthenticated={isAuthenticated}
          exact
          path={ROUTE.REPORT}
          component={ReportPage}
        />
        <DashboardPage>
          <Switch>
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              exact
              path={ROUTE.DASHBOARD}
              render={renderMainPage}
            />
            <ProtectedRoute
              isAuthenticated={isAuthenticated}
              exact
              path={`${ROUTE.DASHBOARD}/nodes/:deviceCode/analysis`}
              component={AnalysisPage}
            />
            <Route>
              <Redirect to={ROUTE.ROOT} />
            </Route>
          </Switch>
        </DashboardPage>
        <Route>
          <Redirect to={ROUTE.ROOT} />
        </Route>
      </Switch>
    </Box>
  );
}

export default App;
