import { Flex } from '@chakra-ui/react';
import React from 'react';

import { IPropTypes } from './Wrapper.types';

export function Wrapper(props: IPropTypes): JSX.Element {
  const { rightComponent, leftComponent } = props;

  return (
    <Flex
      bgColor="bgLightSecondary"
      h="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Flex
        bgColor="bgLightPrimary"
        borderRadius="1rem"
        filter="drop-shadow(4px 4px 8px rgba(130, 130, 130, 0.16))"
        overflow="hidden"
      >
        <Flex>{leftComponent}</Flex>
        {rightComponent && <Flex>{rightComponent}</Flex>}
      </Flex>
    </Flex>
  );
}

export default Wrapper;
