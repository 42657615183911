/* eslint-disable @typescript-eslint/naming-convention */
import Input from 'features/theme/components/Input/Input';

const PinInput = {
  variants: {
    filled: (props: any): Record<string, any> => ({
      ...Input.variants.filled(props).field,
    }),
    // field: {
    //     borderColor: 'red',
    // },
  },
  // baseStyle: {
  // },
  // baseStyle: (props: any) => ({
  // }),
};

export default PinInput;
