import {
  ACCESS_TOKEN_EXPIRE_STORAGE,
  ACCESS_TOKEN_STORAGE,
  PROFILE_AVATAR_URL_STORAGE,
  PROFILE_DEVICE_CODE_STORAGE,
  PROFILE_EMAIL_STORAGE,
  PROFILE_FULLNAME_STORAGE,
  PROFILE_ORGANIZATION_STORAGE,
  PROFILE_ROLE_STORAGE,
  PROFILE_THEME_STORAGE,
  REGISTER_TOKEN_STORAGE,
} from 'constants/storage';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
import { ICookieOption, IProfile, IStorageOption } from 'types/storage';

import { decrypt, encrypt } from '../utils/crypto';

function createCookieStorage(
  _name: string,
  options: IStorageOption,
): {
  _name: string;
  set: (value: string, cookieOptions?: ICookieOption) => void;
  get: () => string;
  remove: () => void;
} {
  return {
    _name,
    set(value, cookieOptions) {
      setCookie(null, this._name, options.encrypt ? encrypt(value) : value, {
        path: '/',
        ...cookieOptions,
      });
    },
    get() {
      const cookie = parseCookies()[this._name];
      return (options.encrypt ? decrypt(cookie) : cookie) as string;
    },
    remove() {
      destroyCookie(null, this._name);
    },
  };
}

function createLocalStorage(_name: string) {
  return {
    _name,
    set(value: string) {
      localStorage.setItem(this._name, JSON.stringify(value));
    },
    get() {
      return JSON.parse(localStorage.getItem(this._name) ?? '');
    },
    remove() {
      localStorage.removeItem(this._name);
    },
  };
}

export const registerTokenStorage = createLocalStorage(REGISTER_TOKEN_STORAGE);

export const accessTokenStorage = {
  _value: createCookieStorage(ACCESS_TOKEN_STORAGE, { encrypt: true }),
  _expire: createCookieStorage(ACCESS_TOKEN_EXPIRE_STORAGE, { encrypt: true }),
  set(value: string, options: ICookieOption): void {
    this._value.set(value, options);
    this._expire.set(options.expires.toISOString(), options);
  },
  get(): { value: string; expire: string } {
    return {
      value: this._value.get(),
      expire: this._expire.get(),
    };
  },
  remove(): void {
    this._value.remove();
    this._expire.remove();
  },
};

export const profileStorage = {
  _deviceCode: createCookieStorage(PROFILE_DEVICE_CODE_STORAGE, {
    encrypt: true,
  }),
  _fullname: createCookieStorage(PROFILE_FULLNAME_STORAGE, { encrypt: true }),
  _email: createCookieStorage(PROFILE_EMAIL_STORAGE, { encrypt: true }),
  _role: createCookieStorage(PROFILE_ROLE_STORAGE, { encrypt: true }),
  _organization: createCookieStorage(PROFILE_ORGANIZATION_STORAGE, {
    encrypt: true,
  }),
  _avatarUrl: createCookieStorage(PROFILE_AVATAR_URL_STORAGE, {
    encrypt: true,
  }),
  _theme: createCookieStorage(PROFILE_THEME_STORAGE, { encrypt: true }),
  set({
    deviceCode,
    fullname,
    email,
    role,
    organization,
    avatarUrl,
    theme,
  }: IProfile): void {
    this._deviceCode.set(deviceCode);
    this._fullname.set(fullname);
    this._email.set(email);
    this._role.set(role);
    this._organization.set(organization);
    this._avatarUrl.set(avatarUrl);
    this._theme.set(theme);
  },
  get(): IProfile {
    return {
      deviceCode: this._deviceCode.get(),
      fullname: this._fullname.get(),
      email: this._email.get(),
      role: this._role.get(),
      organization: this._organization.get(),
      avatarUrl: this._avatarUrl.get(),
      theme: this._theme.get(),
    } as IProfile;
  },
  remove(): void {
    this._deviceCode.remove();
    this._fullname.remove();
    this._email.remove();
    this._role.remove();
    this._organization.remove();
    this._avatarUrl.remove();
    this._theme.remove();
  },
};
