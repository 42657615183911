import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { IPropTypes, ITooltip } from './AreaChart.types';

const renderTooltip = ({ tooltipData, unit }: ITooltip) => {
  const { active: isActive, payload, label } = tooltipData;
  if (isActive && payload && payload.length) {
    return (
      <VStack
        backgroundColor="#F7F8FA"
        padding="3"
        align="stretch"
        rounded="lg"
        boxShadow="md"
      >
        <Flex alignItems="center">
          <Box w="1rem" h="1rem" borderRadius="full" bgColor="#7CB4F0" mr="1" />
          <Text textStyle="button">
            {payload[0].value} {unit}
          </Text>
        </Flex>
        <Text textStyle="body2" color="labelLightSecondary">
          {label}
        </Text>
      </VStack>
    );
  }

  return null;
};

// const formatTick = (tickItem: string) => {
//   // format 28/06/2021 12:00:00 to 28/06
//   const items = tickItem.split('/');
//   return `${items[0]}/${items[1]}`;
// };

export function AreaChartComponent({
  data,
  tooltipUnit = '',
  stroke,
  fill,
  dotStroke,
  dotStrokeWidth = 2,
  activeDotStroke,
  activeDotStrokeWidth = 4,
  activeDotRadius = 2,
}: IPropTypes): JSX.Element {
  // TODO: set tooltip to on top of active dot with
  // reference: https://codesandbox.io/s/how-to-position-tooltip-on-the-top-of-a-barchart-in-rechart-k4lge?file=/src/SessionDuration.css:215-217

  return (
    <ResponsiveContainer width="100%" height={270}>
      <AreaChart
        data={data}
        margin={{
          top: 10,
          right: 30,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid />
        <XAxis
          dataKey="name"
          tick={{ fontSize: 12 }}
          axisLine={{ stroke: '#cccccc' }}
          tickLine={{ stroke: '#35353599' }}
          // tickFormatter={formatTick}
        />
        <YAxis
          tick={{ fontSize: 12 }}
          axisLine={{ stroke: '#cccccc' }}
          tickLine={{ stroke: '#35353599' }}
        />
        <Tooltip
          content={(tooltipData) =>
            renderTooltip({ tooltipData, unit: tooltipUnit })
          }
        />
        <Area
          dataKey="value"
          stroke={stroke}
          fill={fill}
          dot={{ stroke: dotStroke, strokeWidth: dotStrokeWidth }}
          activeDot={{
            stroke: activeDotStroke,
            strokeWidth: activeDotStrokeWidth,
            r: activeDotRadius,
          }}
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default AreaChartComponent;
