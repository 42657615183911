import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const CircleChecklist = createIcon({
  displayName: 'ConfettiIcon',
  viewBox: '0 0 56 56',
  defaultProps: {
    w: '56px',
    h: '56px',
    fill: 'none',
  },
  path: addKeys([
    <rect width="56" height="56" rx="28" fill="#34C759" fillOpacity="0.2" />,
    <path
      d="M20 28.6889L25.0909 33.6667L36 23"
      stroke="#34C759"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ]),
});
