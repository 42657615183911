import {
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FormikInputRightLabel } from 'features/common/components/FormikInput';
import {
  getThreshold,
  setThreshold,
} from 'features/dashboard/pages/AnalysisPage/action';
// import { setThreshold } from 'features/dashboard/pages/AnalysisPage/action';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import React, { useRef } from 'react';
import { number, object } from 'yup';

import { IPropTypes, IThresholdForm } from './ThresholdModal.types';

const thresholdSchema = object({
  high_threshold: number()
    .typeError('Please use a number')
    .required('Required'),
  low_threshold: number().typeError('Please use a number').required('Required'),
  hospital_high_threshold: number()
    .typeError('Please use a number')
    .required('Required'),
  hospital_low_threshold: number()
    .typeError('Please use a number')
    .required('Required'),
});

export function ThresholdModal({ isOpen, onClose }: IPropTypes): JSX.Element {
  const thresholdForm = useRef<FormikProps<IThresholdForm>>(null);

  const { data: rawResponse, error } = getThreshold();
  const data = rawResponse?.data as IThresholdForm;

  const handleSubmit = async (
    values: IThresholdForm,
    { setStatus, setSubmitting, setValues }: FormikHelpers<IThresholdForm>,
  ) => {
    setSubmitting(true);

    try {
      const { data, code } = await setThreshold(values);

      if (!data || code !== 200) {
        setStatus('Cannot update threshold. try again later.');
        setSubmitting(false);
        return;
      }

      setValues(data);
      setSubmitting(false);
    } catch (error) {
      setStatus('Cannot update threshold. try again later.');
      setSubmitting(false);
      return;
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="6">
        <VStack align="stretch" spacing="6">
          <Flex alignItems="center" justifyContent="space-between">
            <Text textStyle="heading1">Threshold Setting</Text>
            <CloseButton onClick={onClose} />
          </Flex>
          <Formik
            innerRef={thresholdForm}
            initialValues={{ ...data }}
            validationSchema={thresholdSchema}
            onSubmit={handleSubmit}
          >
            {({ status, isValid, isSubmitting, values, submitForm }) => {
              return (
                <Form>
                  <VStack spacing="4">
                    <FormikInputRightLabel
                      id="high_threshold"
                      name="high_threshold"
                      label="High Limit"
                      placeholder="High Limit"
                      labelAlwaysActive={values.high_threshold !== ''}
                    />
                    <FormikInputRightLabel
                      id="low_threshold"
                      name="low_threshold"
                      label="Low Limit"
                      placeholder="Low Limit"
                      labelAlwaysActive={values.low_threshold !== ''}
                    />
                    <FormikInputRightLabel
                      id="hospital_high_threshold"
                      name="hospital_high_threshold"
                      label="High Limit Hospital"
                      placeholder="High Limit Hospital"
                      labelAlwaysActive={values.hospital_high_threshold !== ''}
                    />
                    <FormikInputRightLabel
                      id="hospital_low_threshold"
                      name="hospital_low_threshold"
                      label="Low Limit Hospital"
                      placeholder="Low Limit Hospital"
                      labelAlwaysActive={values.hospital_low_threshold !== ''}
                    />
                    <Button
                      type="submit"
                      variant="default"
                      onClick={submitForm}
                      colorScheme="blue"
                      width="full"
                      isDisabled={!isValid}
                      isLoading={isSubmitting}
                    >
                      Submit
                    </Button>
                    {status && (
                      <Text textStyle="body2" color="red" w="full">
                        Error: {status}
                      </Text>
                    )}
                  </VStack>
                </Form>
              );
            }}
          </Formik>
        </VStack>
      </ModalContent>
    </Modal>
  );
}

export default ThresholdModal;
