import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const Help = createIcon({
  displayName: 'Help',
  viewBox: '0 0 24 24',
  defaultProps: {
    w: '24px',
    h: '24px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M12.5 1.5C6.70156 1.5 2 6.20156 2 12C2 17.7984 6.70156 22.5 12.5 22.5C18.2984 22.5 23 17.7984 23 12C23 6.20156 18.2984 1.5 12.5 1.5ZM12.5 20.7188C7.68594 20.7188 3.78125 16.8141 3.78125 12C3.78125 7.18594 7.68594 3.28125 12.5 3.28125C17.3141 3.28125 21.2188 7.18594 21.2188 12C21.2188 16.8141 17.3141 20.7188 12.5 20.7188Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
    <path
      d="M15.1156 7.42266C14.4125 6.80625 13.4844 6.46875 12.5 6.46875C11.5156 6.46875 10.5875 6.80859 9.88437 7.42266C9.15312 8.0625 8.75 8.92266 8.75 9.84375V10.0219C8.75 10.125 8.83438 10.2094 8.9375 10.2094H10.0625C10.1656 10.2094 10.25 10.125 10.25 10.0219V9.84375C10.25 8.81016 11.2602 7.96875 12.5 7.96875C13.7398 7.96875 14.75 8.81016 14.75 9.84375C14.75 10.5727 14.2344 11.2406 13.4352 11.5477C12.9383 11.7375 12.5164 12.0703 12.2141 12.5062C11.907 12.9516 11.7477 13.4859 11.7477 14.0273V14.5312C11.7477 14.6344 11.832 14.7188 11.9352 14.7188H13.0602C13.1633 14.7188 13.2477 14.6344 13.2477 14.5312V13.9992C13.2489 13.7717 13.3186 13.5498 13.4478 13.3625C13.577 13.1752 13.7596 13.0312 13.9719 12.9492C15.3547 12.4172 16.2477 11.1984 16.2477 9.84375C16.25 8.92266 15.8469 8.0625 15.1156 7.42266ZM11.5625 17.1562C11.5625 17.4049 11.6613 17.6433 11.8371 17.8192C12.0129 17.995 12.2514 18.0938 12.5 18.0938C12.7486 18.0938 12.9871 17.995 13.1629 17.8192C13.3387 17.6433 13.4375 17.4049 13.4375 17.1562C13.4375 16.9076 13.3387 16.6692 13.1629 16.4933C12.9871 16.3175 12.7486 16.2188 12.5 16.2188C12.2514 16.2188 12.0129 16.3175 11.8371 16.4933C11.6613 16.6692 11.5625 16.9076 11.5625 17.1562Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
  ]),
});
