import {
  Box,
  Flex,
  HStack,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { BatteryIcon, InfoIcon, SunIcon } from 'features/common/assets';
import { GaugeCharts as GaugeChart } from 'features/common/components';
import { useHover } from 'features/common/utils/hooks.utils';
import { gaugeStatus } from 'features/dashboard/constant';
import { EGaugeType } from 'features/dashboard/types';
import { Card } from 'features/theme/factories';
import colors from 'features/theme/foundations/colors';
import moment from 'moment';
import React from 'react';

import { IPropTypes } from './GaugeCharts.types';

export function GaugeCharts(props: IPropTypes): JSX.Element {
  const [hoverRef, isHovered] = useHover<SVGSVGElement>();

  const { gaugeData, gaugeType = EGaugeType.type1 } = props;
  const {
    device_name,
    status,
    updated_at,
    pressure_value,
    battery_level,
    solar_exposure,
    firmware_version,
    memory_usage,
    device_type,
  } = gaugeData;
  const deviceStatus = gaugeStatus.findIndex((item) => item.name == status);
  const renderTooltip = (): JSX.Element => (
    <Box
      boxShadow="2px 4px 16px 0px rgba(0, 0, 0, 0.16)"
      bgColor="bgLightSecondary"
      borderRadius={3}
      position="absolute"
      width="300px"
      p={4}
    >
      <Flex justifyContent="space-between">
        <Text textStyle="caption" color="rgba(53, 53, 53, 0.6)" mb={2}>
          Memory Usage
        </Text>
        <Text textStyle="caption" color="primary.600">
          {memory_usage}
        </Text>
      </Flex>

      <Flex justifyContent="space-between" color="rgba(53, 53, 53, 0.6)">
        <Text textStyle="caption" color="rgba(53, 53, 53, 0.6)" mb={2}>
          Device Type
        </Text>
        <Text textStyle="caption" color="primary.600">
          {device_type}
        </Text>
      </Flex>

      <Flex justifyContent="space-between" color="rgba(53, 53, 53, 0.6)">
        <Text textStyle="caption" color="rgba(53, 53, 53, 0.6)" mb={2}>
          Firmware Version
        </Text>
        <Text textStyle="caption" color="primary.600">
          {firmware_version}
        </Text>
      </Flex>
    </Box>
  );
  const renderGaugeInfoV2 = () => (
    <Box bgColor="bgLightSecondary" borderRadius={2} p={8} mt={10}>
      <Flex justifyContent="space-between" mb={8}>
        <Text textStyle="heading1" color="labelLightPrimary">
          Telementary Info
        </Text>

        <Flex>
          <Flex alignItems="center">
            <SunIcon mr={1} />
            <Text textStyle="body1" color="primary.600">
              {`${solar_exposure}%`}
            </Text>
          </Flex>
          <Box mr={3} />
          <Flex alignItems="center">
            <BatteryIcon mr={1} />
            <Text textStyle="body1" color="primary.600">
              {`${battery_level}%`}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <HStack divider={<StackDivider borderColor="grey.500" />} align="stretch">
        <Box pr={12} flex="1">
          <Text
            textStyle="subheading2"
            color={colors.labelLightSecondary}
            mb={2}
          >
            Memory Usage
          </Text>
          <Text textStyle="heading2" color="primary.600">
            {memory_usage}
          </Text>
        </Box>
        <Box pr={12} pl={3} flex="1">
          <Text
            textStyle="subheading2"
            color={colors.labelLightSecondary}
            mb={2}
          >
            Device Type
          </Text>
          <Text textStyle="heading2" color="primary.600">
            {device_type}
          </Text>
        </Box>
        <Box pr={12} pl={3} flex="1">
          <Text
            textStyle="subheading2"
            color={colors.labelLightSecondary}
            mb={2}
          >
            Firmware Version
          </Text>
          <Text textStyle="heading2" color="primary.600">
            {firmware_version}
          </Text>
        </Box>
      </HStack>
    </Box>
  );

  return (
    <Card p={6}>
      <VStack alignItems="unset">
        <Flex justifyContent="space-between">
          <Flex flexDirection="column">
            <Flex alignItems="center" mb={2}>
              <Box
                mr={2}
                w="1rem"
                h="1rem"
                borderRadius="full"
                bgColor={gaugeStatus[deviceStatus]?.color}
              />
              <Text textStyle="heading2" color="labelLightPrimary">
                {device_name}
              </Text>
            </Flex>
            <Text textStyle="caption" color="rgba(53, 53, 53, 0.6)" mb={3}>
              {`Last update ${moment(updated_at).format('DD/MM/YYYY, HH:mm ')}`}
            </Text>

            {gaugeType === EGaugeType.type1 && (
              <Box position="relative">
                <InfoIcon ref={hoverRef} />
                {isHovered && renderTooltip()}
              </Box>
            )}
          </Flex>
          {gaugeType === EGaugeType.type1 && (
            <Flex flexDirection="column">
              <Flex alignItems="center">
                <BatteryIcon mr={2} />
                <Text textStyle="body1" color="primary.600">
                  {`${battery_level}%`}
                </Text>
              </Flex>
              <Flex alignItems="center">
                <SunIcon mr={2} />
                <Text textStyle="body1" color="primary.600">
                  {`${solar_exposure}%`}
                </Text>
              </Flex>
            </Flex>
          )}
        </Flex>

        <Box mt={10} />

        <Box alignItems="center">
          <GaugeChart pressureValue={pressure_value} />
        </Box>
        <Flex justifyContent="center">
          <Box
            borderRadius="0.875rem"
            bgColor="bgLightTertiary"
            px={3}
            py={4}
            mb={3}
          >
            <Flex flexDirection="row" alignItems="center">
              <Text textStyle="subheading2" color="labelLightPrimary" mr={2}>
                {pressure_value}
              </Text>
              <Text textStyle="body1" color="rgba(53, 53, 53, 0.6)">
                InH2O
              </Text>
            </Flex>
          </Box>
        </Flex>

        {gaugeType === EGaugeType.type2 && renderGaugeInfoV2()}
      </VStack>
    </Card>
  );
}

export default GaugeCharts;
