import { Button, Flex, Stack, Text } from '@chakra-ui/react';
import { ExportFile } from 'features/common/assets';
import React from 'react';

import { IPropTypes } from './Section.types';

export function Section(props: IPropTypes): JSX.Element {
  const { title, hasRightComponent, children, onButtonExportClick } = props;

  return (
    <Stack spacing={6}>
      <Flex justifyContent="space-between" alignItems="center">
        <Text textStyle="heading1" color="labelLightPrimary">
          {title}
        </Text>

        {hasRightComponent && (
          <Button
            leftIcon={<ExportFile />}
            textStyle="button"
            color="labelLightSecondary"
            variant="solid"
            onClick={() => onButtonExportClick()}
          >
            Export
          </Button>
        )}
      </Flex>

      <div>{children}</div>
    </Stack>
  );
}

export default Section;
