export const REGISTER_TOKEN_STORAGE = 'aeonium';
export const ACCESS_TOKEN_STORAGE = 'bamboo';
export const REFRESH_TOKEN_STORAGE = 'cactus';
export const ACCESS_TOKEN_EXPIRE_STORAGE = 'daphne';
export const PROFILE_FULLNAME_STORAGE = 'echeveria';
export const PROFILE_EMAIL_STORAGE = 'favabean';
export const PROFILE_ROLE_STORAGE = 'gardenia';
export const PROFILE_ORGANIZATION_STORAGE = 'hakone';
export const PROFILE_THEME_STORAGE = 'inkberry';
export const PROFILE_AVATAR_URL_STORAGE = 'japanese';
export const PROFILE_DEVICE_CODE_STORAGE = 'kale';
