import {
  Box,
  Container,
  Input,
  InputGroup,
  InputRightElement,
  StackDivider,
  Text,
  VStack,
} from '@chakra-ui/react';
import { SearchIcon } from 'features/common/assets';
import React, { useState } from 'react';

import { AssetTrackerCardItem } from '../AssetTrackerCardItem';
import { IPropTypes } from './AssetTrackerCard.types';

export function AssetTrackerCard(props: IPropTypes): JSX.Element {
  const { devices, changeLocation } = props;
  const [search, setSearch] = useState('');
  return (
    <Container
      rounded="lg"
      boxShadow="4px 4px 8px rgba(130, 130, 130, 0.16)"
      p="0"
    >
      <Box bgColor="bgLightSecondary" roundedTop="lg" py="5" px="5">
        <Text textStyle="heading1">Asset Tracker</Text>
      </Box>

      <Box
        bgColor="white"
        roundedBottom="lg"
        py="5"
        px="5"
        maxH="60vh"
        overflow="auto"
      >
        <InputGroup>
          <Input
            placeholder="Search asset, or location"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          <InputRightElement>
            <SearchIcon />
          </InputRightElement>
        </InputGroup>

        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          py="4"
          align="stretch"
          spacing="4"
        >
          {devices
            .filter((x) => x.name.toLowerCase().includes(search))
            .map((device, i) => (
              <AssetTrackerCardItem
                device={device}
                key={i}
                changeLocation={changeLocation}
              />
            ))}
        </VStack>
      </Box>
    </Container>
  );
}

export default AssetTrackerCard;
