import ENDPOINT from 'constants/endpoint';
import { IDevice } from 'features/dashboard/pages/DashboardHomePage/DashboardHomePage.types';
import useSwr from 'swr';
import { IResponseApi } from 'types/api';
import { accessTokenStorage } from 'utils/storage';

import { createSwrKey, defaultOptions, fetcher } from './utils';

export function useLocation(
  organizationId: string,
  options = defaultOptions,
): IResponseApi<IDevice[]> {
  const { data, error, mutate } = useSwr<any, any>(
    createSwrKey(ENDPOINT.DEVICES_LOCATION, {
      params: { organization_id: organizationId },
    }),
    fetcher({
      method: 'GET',
      headers: { Authorization: `bearer ${accessTokenStorage.get().value}` },
    }),
    { ...options, refreshInterval: 0 },
  );
  return {
    data: data?.data,
    isLoading: false,
    error,
    mutate,
  };
}
