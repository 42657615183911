import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { ForgotPasswordForm, Wrapper } from 'features/auth/components';
import { IForgotPasswordForm } from 'features/auth/types';
import ShieldImage from 'features/common/assets/Shield.png';
import { Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';

import { IPropTypes } from './ForgotPasswordPage.types';

export function ForgotPasswordPage(props: IPropTypes): JSX.Element {
  // const { } = props;

  const forgotPasswordForm = useRef<FormikProps<IForgotPasswordForm>>(null);

  const handleSubmit = (values: IForgotPasswordForm) => {
    console.warn('this is login', values);
  };

  const renderLeftComponent = (): JSX.Element => (
    <Box px="4.5rem" py="11.25rem">
      <Text textStyle="bigTitle">Forgot your password?</Text>
      <Box mb={3} />
      <Text textStyle="subtitle">Don’t worry, we got you.</Text>

      <Box mb="5.25rem" />

      <Formik
        innerRef={forgotPasswordForm}
        initialValues={{ email: '' }}
        onSubmit={handleSubmit}
      >
        {({ isValid, submitForm }) => {
          return (
            <Form>
              <ForgotPasswordForm />

              <Button
                type="submit"
                variant="default"
                onClick={submitForm}
                isDisabled={!isValid}
                isFullWidth
              >
                Send reset instruction
              </Button>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );

  const renderRightComponent = () => (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      px="10rem"
    >
      <Image src={ShieldImage} />
    </Flex>
  );

  return (
    <Wrapper
      leftComponent={renderLeftComponent()}
      rightComponent={renderRightComponent()}
    />
  );
}

export default ForgotPasswordPage;
