import 'leaflet/dist/leaflet.css';
import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';

import { Box, Container, Flex, Text, VStack } from '@chakra-ui/react';
import { BatteryIcon, SunIcon } from 'features/common/assets';
import { gaugeStatus } from 'features/dashboard/constant';
import { AssetTrackerCard } from 'features/tracker/components';
import L, { LatLng, LatLngTuple, Map } from 'leaflet';
// import icon from 'leaflet/dist/images/marker-icon.png';
// import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import GestureHandling from 'leaflet-gesture-handling';
import React, { useEffect, useState } from 'react';
import {
  MapContainer,
  Marker,
  TileLayer,
  Tooltip,
  useMap,
} from 'react-leaflet';

import { getNodesLocation } from './action';
import { mapDeviceModelToView } from './mapper';
import { IDeviceView } from './TrackerHomePage.types';

type LocationMarkerProps = {
  device: IDeviceView;
};

function LocationMarker({ device }: LocationMarkerProps) {
  const {
    id,
    name,
    latitude,
    longitude,
    battery_level,
    solar_exposure,
    pressure_value,
    status,
  } = device;

  const gauge = gaugeStatus.find((x) => x.name === status) ?? gaugeStatus[1];

  return (
    <Marker position={new LatLng(latitude, longitude)} key={id}>
      <Tooltip>
        <VStack align="stretch" spacing={2}>
          <Flex direction="row" justifyContent="space-between">
            <Flex alignItems="center">
              <Text textStyle="body2" color="labelLightPrimary">
                {name}
              </Text>
              <Box
                ml={2}
                w="0.8rem"
                h="0.8rem"
                borderRadius="full"
                bgColor={gauge.color}
              />
            </Flex>
            <Box w="10" />
            <Flex alignItems="center">
              <BatteryIcon h="4" />
              <Text textStyle="small" color="primary.600">
                {`${battery_level}%`}
              </Text>
            </Flex>
          </Flex>
          <Flex direction="row" justifyContent="space-between">
            <Text textStyle="small" color="labelLightSecondary">
              {`Last update 15/12/2021`}
            </Text>
            <Flex alignItems="center">
              <SunIcon h="4" />
              <Text textStyle="small" color="primary.600">
                {`${solar_exposure}%`}
              </Text>
            </Flex>
          </Flex>
          <Flex direction="row" justifyContent="space-between">
            <Text textStyle="small" color="labelLightSecondary">
              Pressure
            </Text>
            <Text textStyle="small" color="primary.600">
              {pressure_value} inH2O
            </Text>
          </Flex>
        </VStack>
      </Tooltip>
    </Marker>
  );
}

export function TrackerHomePage(): JSX.Element {
  const [devices, setDevices] = useState<IDeviceView[]>([]);
  const initialLocation: LatLngTuple = [-6.8842714, 107.5505391];
  const [map, setMap] = useState<Map>();
  const [isInit, setIsInit] = useState(true);

  // setup leaflet icon
  const DefaultIcon = L.icon({
    iconUrl: process.env.PUBLIC_URL + '/pin_location.png',
    // shadowUrl: iconShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  L.Marker.prototype.options.icon = DefaultIcon;

  const changeLocation = (coord: LatLngTuple) => {
    map?.flyTo(coord, 17);
  };

  const GestureHandlingSetter = () => {
    /* eslint-disable */
    const map = useMap() as any;
    map.gestureHandling.enable();
    map.addHandler('gestureHandling', GestureHandling);
    /* eslint-enable */
    return null;
  };

  useEffect(() => {
    // set init to false after 3s
    setTimeout(() => {
      if (isInit) setIsInit(false);
    }, 3000);
  }, []);

  useEffect(() => {
    async function loadDevices() {
      try {
        const { data } = await getNodesLocation({
          organization_id: 1, // use dynamic data
        });

        const devices = data?.map((x) => mapDeviceModelToView(x)) ?? [];
        setDevices(devices);

        // fly to first node's location
        // if (devices.length > 0) {
        //   const { latitude, longitude } = devices[0];
        //   changeLocation([latitude, longitude]);
        // }
      } catch (error) {
        console.error('error', error);
      }
    }

    loadDevices();
  }, []);

  return (
    <Container maxW="5120px" w="100%" h="100vh" p="0" m="0" position="relative">
      <Box py="24" px="28" position="absolute" top="0" left="0" zIndex="800">
        <AssetTrackerCard devices={devices} changeLocation={changeLocation} />
      </Box>

      <MapContainer
        id="mapId"
        center={initialLocation}
        zoom={17}
        zoomControl={false}
        scrollWheelZoom={false}
        whenCreated={(map) => setMap(map)}
      >
        {isInit && <GestureHandlingSetter />}
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {/* <ZoomControl position="topright" /> */}
        {devices.map((device) => (
          <LocationMarker device={device} key={device.id} />
        ))}
      </MapContainer>
    </Container>
  );
}

export default TrackerHomePage;
