import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { Card } from 'features/theme/factories';
import moment from 'moment';
import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { IPropTypes, ITooltip } from './BarChart.types';

const marginBar = {
  top: 5,
  right: 0,
  left: -20,
  bottom: 5,
};

export function BarChartCard(props: IPropTypes): JSX.Element {
  const { data, title, dataKey, dataKeyValue } = props;
  const renderTooltip = ({ tooltipData, unit }: ITooltip) => {
    const { active: isActive, payload, label } = tooltipData;
    if (isActive && payload && payload.length) {
      return (
        <VStack
          backgroundColor="#F7F8FA"
          padding="3"
          align="stretch"
          rounded="lg"
          boxShadow="md"
        >
          <Flex alignItems="center">
            <Box
              w="1rem"
              h="1rem"
              borderRadius="full"
              bgColor="#7CB4F0"
              mr="1"
            />
            <Text textStyle="button">
              {payload[0].value} {unit}
            </Text>
          </Flex>
          <Text textStyle="body2" color="labelLightSecondary">
            {label}
          </Text>
        </VStack>
      );
    }

    return null;
  };

  return (
    <Flex>
      <Card p={5} flex={1}>
        <Text textStyle="heading2" mb={2}>
          {title}
        </Text>
        {data?.length > 0 ? (
          <ResponsiveContainer width="100%" height="91%">
            <BarChart width={500} height={300} data={data} margin={marginBar}>
              <CartesianGrid />
              <XAxis
                dataKey={dataKey}
                style={{ font: 'inherit', backgroundColor: 'green' }}
                interval={0}
              />
              <YAxis />
              <Tooltip
                content={(tooltipData) =>
                  renderTooltip({ tooltipData, unit: '' })
                }
              />
              <Bar dataKey={dataKeyValue} fill="#509BEB" />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Text>No Data</Text>
        )}
      </Card>
    </Flex>
  );
}

export default BarChartCard;
