import { Box, Text } from '@chakra-ui/react';
import { FormikInput } from 'features/common/components';
import React from 'react';

import { IPropTypes } from './ForgotPasswordForm.types';

export function ForgotPasswordForm(props: IPropTypes): JSX.Element {
  const { onSubmit } = props;

  return (
    <Box>
      <Text textStyle="body1" color="labelLightSecondary" opacity="0.6" mb={6}>
        Enter your registered email address to reset your password.
      </Text>

      <Box mb={8}>
        <FormikInput
          id="email"
          name="email"
          placeholder="email address"
          label="email address"
        />
      </Box>
    </Box>
  );
}

export default ForgotPasswordForm;
