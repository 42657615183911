/* eslint-disable @typescript-eslint/naming-convention */
import textStyles from 'features/theme/foundations/textStyles';

const Alert = {
  variants: {
    default: (): Record<string, any> => ({
      container: {
        boxShadow: '0px 4px 4px rgba(130, 130, 130, 0.25)',
        bgColor: 'bgLightPrimary',
        borderRadius: '50px',
        p: '0.5rem',
      },
      title: {
        ...textStyles.subheading2,
        color: 'labelLightPrimary',
        ml: '1rem',
      },
    }),
  },
};

export default Alert;
