import { Box, Flex, Spinner, VStack } from '@chakra-ui/react';
import { FormikInput, FormikInputPassword } from 'features/common/components';
import React from 'react';

import { IPropTypes } from './LoginForm.types';

export function LoginForm(props: IPropTypes): JSX.Element {
  const { isLoading } = props;

  return (
    <VStack position="relative">
      {isLoading && (
        <Flex
          position="absolute"
          top={0}
          bottom={0}
          left={0}
          right={0}
          alignItems="center"
          justifyContent="center"
          backgroundColor="rgba(255, 255, 255, 0.5)"
          zIndex={2}
        >
          <Spinner color="primary.500" size="xl" data-testid="spinner" />
        </Flex>
      )}
      <Box w="100%" mb={8}>
        <FormikInput
          id="email"
          name="email"
          label="email"
          placeholder="email"
        />
      </Box>
      <Box w="100%" mb="2rem !important" mt="0 !important">
        <FormikInputPassword
          id="password"
          name="password"
          label="password"
          placeholder="password"
        />
      </Box>
    </VStack>
  );
}

export default LoginForm;
