import { IDeviceLocationModel, IDeviceView } from './TrackerHomePage.types';

export function mapDeviceModelToView(
  device: IDeviceLocationModel,
): IDeviceView {
  return {
    ...device,
    latitude: Number(device.latitude),
    longitude: Number(device.longitude),
  };
}
