/**
 *
 * @param meridiem the meridiem to convert to 24 hour time
 * @param hour the hour to convert
 *
 * this function will convert a 12 hour time to 24 hour time
 */
export function getHourByMeridiem(meridiem: string, hour: number): number {
  if (meridiem === 'AM' && hour > 12) return hour - 12;
  else if (meridiem === 'PM' && hour <= 12) return hour + 12;

  return hour;
}
