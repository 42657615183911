import {
  Button,
  CloseButton,
  Flex,
  HStack,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { CSVLink } from 'react-csv';

import { IPropTypes } from './PromptModal.types';

export function PromptModal({
  title,
  body,
  cancelText,
  confirmText,
  isOpen,
  onClose,
  onConfirm,
  isCsvPrint,
  csvData,
  csvFilename,
}: IPropTypes): JSX.Element {
  function ConfirmButton() {
    if (isCsvPrint) {
      return (
        <CSVLink
          data={csvData ?? []}
          filename={csvFilename ?? 'csv file'}
          style={{ width: '100%' }}
        >
          <Button
            variant="default"
            colorScheme="blue"
            onClick={onConfirm}
            w="full"
          >
            {confirmText}
          </Button>
        </CSVLink>
      );
    }

    return (
      <Button variant="default" colorScheme="blue" onClick={onConfirm} w="full">
        {confirmText}
      </Button>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent p="6">
        <VStack align="stretch" spacing="6">
          <Flex alignItems="center" justifyContent="space-between">
            <Text textStyle="heading1">{title}</Text>
            <CloseButton onClick={onClose} />
          </Flex>
          <Text textStyle="body1" color="labelLightSecondary">
            {body}
          </Text>
          <HStack align="stretch" spacing="4">
            <Button
              textStyle="button"
              color="labelLightSecondary"
              variant="solid"
              w="full"
              onClick={onClose}
            >
              {cancelText}
            </Button>
            <ConfirmButton />
          </HStack>
        </VStack>
      </ModalContent>
    </Modal>
  );
}

export default PromptModal;
