import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const FullScreen = createIcon({
  displayName: 'FullScreen',
  viewBox: '0 0 24 24',
  defaultProps: {
    w: '24px',
    h: '24px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M5.5 6C5.5 5.73478 5.60536 5.48043 5.79289 5.29289C5.98043 5.10536 6.23478 5 6.5 5H8.5C8.76522 5 9.01957 4.89464 9.20711 4.70711C9.39464 4.51957 9.5 4.26522 9.5 4C9.5 3.73478 9.39464 3.48043 9.20711 3.29289C9.01957 3.10536 8.76522 3 8.5 3H6.5C5.70435 3 4.94129 3.31607 4.37868 3.87868C3.81607 4.44129 3.5 5.20435 3.5 6V8C3.5 8.26522 3.60536 8.51957 3.79289 8.70711C3.98043 8.89464 4.23478 9 4.5 9C4.76522 9 5.01957 8.89464 5.20711 8.70711C5.39464 8.51957 5.5 8.26522 5.5 8V6Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
    <path
      d="M5.5 18C5.5 18.2652 5.60536 18.5196 5.79289 18.7071C5.98043 18.8946 6.23478 19 6.5 19H8.5C8.76522 19 9.01957 19.1054 9.20711 19.2929C9.39464 19.4804 9.5 19.7348 9.5 20C9.5 20.2652 9.39464 20.5196 9.20711 20.7071C9.01957 20.8946 8.76522 21 8.5 21H6.5C5.70435 21 4.94129 20.6839 4.37868 20.1213C3.81607 19.5587 3.5 18.7956 3.5 18V16C3.5 15.7348 3.60536 15.4804 3.79289 15.2929C3.98043 15.1054 4.23478 15 4.5 15C4.76522 15 5.01957 15.1054 5.20711 15.2929C5.39464 15.4804 5.5 15.7348 5.5 16V18Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
    <path
      d="M18.5 5C18.7652 5 19.0196 5.10536 19.2071 5.29289C19.3946 5.48043 19.5 5.73478 19.5 6V8C19.5 8.26522 19.6054 8.51957 19.7929 8.70711C19.9804 8.89464 20.2348 9 20.5 9C20.7652 9 21.0196 8.89464 21.2071 8.70711C21.3946 8.51957 21.5 8.26522 21.5 8V6C21.5 5.20435 21.1839 4.44129 20.6213 3.87868C20.0587 3.31607 19.2956 3 18.5 3H16.5C16.2348 3 15.9804 3.10536 15.7929 3.29289C15.6054 3.48043 15.5 3.73478 15.5 4C15.5 4.26522 15.6054 4.51957 15.7929 4.70711C15.9804 4.89464 16.2348 5 16.5 5H18.5Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
    <path
      d="M19.5 18C19.5 18.2652 19.3946 18.5196 19.2071 18.7071C19.0196 18.8946 18.7652 19 18.5 19H16.5C16.2348 19 15.9804 19.1054 15.7929 19.2929C15.6054 19.4804 15.5 19.7348 15.5 20C15.5 20.2652 15.6054 20.5196 15.7929 20.7071C15.9804 20.8946 16.2348 21 16.5 21H18.5C19.2956 21 20.0587 20.6839 20.6213 20.1213C21.1839 19.5587 21.5 18.7956 21.5 18V16C21.5 15.7348 21.3946 15.4804 21.2071 15.2929C21.0196 15.1054 20.7652 15 20.5 15C20.2348 15 19.9804 15.1054 19.7929 15.2929C19.6054 15.4804 19.5 15.7348 19.5 16V18Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
  ]),
});
