/* eslint-disable @typescript-eslint/naming-convention */
import { mode } from '@chakra-ui/theme-tools';
import textStyles from 'features/theme/foundations/textStyles';

const Input = {
  variants: {
    outline: (props: any): Record<string, any> => ({
      _placeholder: {
        ...textStyles.body1,
        color: 'red.500',
      },

      _focus: {
        _placeholder: {
          color: 'white',
        },
      },
    }),
    filled: (props: any): Record<string, any> => ({
      field: {
        borderRadius: '8px',
        border: '1px solid',
        borderColor: mode('grey.400', 'bgElevatedPrimary')(props),
        bgColor: mode('bgLightPrimary', 'bgDarkPrimary')(props),

        _placeholder: {
          ...textStyles.body1,
          color: mode('labelLightSecondary', 'labelDarkSecondary')(props),
        },

        _hover: {
          borderColor: mode('grey.500', 'rgba(242, 242, 247, 0.3)')(props),
          bgColor: mode('bgLightPrimary', 'bgDarkPrimary')(props),
        },

        _focus: {
          borderColor: mode('grey.500', 'rgba(242, 242, 247, 0.3)')(props),
        },
      },
    }),
  },
};

export default Input;
