import { IRelativeTIme } from 'features/dashboard/components/DateTimePicker/DateTimePicker.types';

import { IProfile } from './storage';

export interface IAnalysisTimeRange {
  isRelative: boolean;
  relativeProps?: IRelativeTIme;
}

export interface IState {
  isDarkMode: boolean;
  isLogin: boolean;
  profile: IProfile;
  analysis: {
    filter: IAnalysisTimeRange;
  };
  report: {
    filter: IAnalysisTimeRange;
  };
}

export const ACTION_TYPES = {
  SET_IS_LOGIN: 'SET_IS_LOGIN',
  SET_IS_DARK_MODE: 'SET_IS_DARK_MODE',
  SET_PROFILE: 'SET_PROFILE',
  SET_ANALYSIS_TIME_RANGE: 'SET_ANALYSIS_TIME_RANGE',
  SET_REPORT_TIME_RANGE: 'SET_REPORT_TIME_RANGE',
};

export type ActionType =
  | 'SET_IS_DARK_MODE'
  | 'SET_PROFILE'
  | 'SET_IS_LOGIN'
  | 'SET_ANALYSIS_TIME_RANGE'
  | 'SET_REPORT_TIME_RANGE';

export interface IAction<T = any> {
  type: ActionType;
  payload?: T;
}

export type ReducerType = (state: IState, action: IAction) => IState;
