import ENDPOINT from 'constants/endpoint';
import { ILogin } from 'features/auth/types';
import { IBaseModel, IRequestOption } from 'types/api';
import fetch from 'utils/fetch';
import { accessTokenStorage, profileStorage } from 'utils/storage';

import { ILoginSuccess, IProfileData } from './LoginPage.types';

export async function postLogin(data: ILogin): Promise<ILoginSuccess> {
  const options: IRequestOption = { method: 'POST', data };
  try {
    const response = await fetch<null, ILoginSuccess>(ENDPOINT.LOGIN, options);
    const { token, expires_at } = response.data;
    accessTokenStorage.set(token, {
      expires: new Date(expires_at),
    });

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

export async function getMyProfile(): Promise<IBaseModel<IProfileData>> {
  const options: IRequestOption = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessTokenStorage.get().value}`,
    },
  };

  try {
    const response = await fetch<null, IBaseModel<IProfileData>>(
      ENDPOINT.PROFILE,
      options,
    );

    if (response.code !== 200 || !response.data) {
      throw new Error(response.message);
    }
    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}
