import './index.css';

import { ChakraProvider } from '@chakra-ui/react';
import { GlobalProvider } from 'context/global';
import { App } from 'features/intellisense/components';
import theme from 'features/theme';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { IState } from 'types/context';
import { accessTokenStorage, profileStorage } from 'utils/storage';
const token = accessTokenStorage.get();
const isLogin = Boolean(token && token.value);
const profile = profileStorage.get();

const initialState: IState = {
  isDarkMode: false,
  isLogin: isLogin,
  profile: profile,
  analysis: {
    filter: {
      isRelative: false,
    },
  },
  report: {
    filter: {
      isRelative: false,
    },
  },
};

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <ChakraProvider resetCSS theme={theme}>
        <GlobalProvider initialState={initialState}>
          <App />
        </GlobalProvider>
      </ChakraProvider>
    </React.StrictMode>
  </Router>,
  document.getElementById('root'),
);
