/* eslint-disable prefer-const */
import './style.css';

import * as d3 from 'd3';
import React, { Component } from 'react';

import { IPropTypes } from './GaugeCharts.types';
class GaugeCharts extends Component<IPropTypes> {
  private _gaugeRef = React.createRef<HTMLDivElement>();
  static value = 17;
  static gaugeMaxValue = 250;
  static percentValue = this.value / this.gaugeMaxValue;
  // static needleClient
  componentDidMount() {
    const { pressureValue } = this.props;
    this.renderChart(pressureValue);
  }

  renderChart = (pressureValue: number) => {
    let barWidth,
      chart: d3.Selection<SVGGElement, unknown, null, undefined>,
      chartInset,
      height,
      margin,
      numSections,
      padRad: number,
      percToRad: { (arg0: number): any; (perc: any): number },
      sectionPerc,
      percent,
      radius,
      svg,
      totalPercent: number,
      width;
    percent = GaugeCharts.percentValue;
    numSections = 1;
    sectionPerc = 1 / numSections / 2;
    padRad = 0.025;
    chartInset = 10;

    // Orientation of gauge:
    totalPercent = 0.75;
    const el = d3.select(this._gaugeRef.current);
    margin = {
      top: 20,
      right: 20,
      bottom: 30,
      left: 20,
    };
    width = 400 - margin.left - margin.right;
    height = 400;
    radius = Math.min(width, height) / 2;
    barWidth = (40 * width) / 300;

    const percToDeg = (perc: any) => perc * 360;

    const degToRad = (deg: any) => (deg * Math.PI) / 180;

    percToRad = (perc: any) => degToRad(percToDeg(perc));

    svg = el
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', 250);

    // Add layer for the panel
    chart = svg
      .append('g')
      .attr(
        'transform',
        'translate(' +
          (width + margin.left) / 2 +
          ', ' +
          (height + margin.top) / 2 +
          ')',
      );

    chart.append('path').attr('class', 'arc chart-first');
    chart.append('path').attr('class', 'arc chart-second');
    chart.append('path').attr('class', 'arc chart-third');

    const arc3 = d3
      .arc<any>()
      .outerRadius(radius - chartInset)
      .innerRadius(radius - chartInset - barWidth);
    const arc2 = d3
      .arc<any>()
      .outerRadius(radius - chartInset)
      .innerRadius(radius - chartInset - barWidth);
    const arc1 = d3
      .arc<any>()
      .outerRadius(radius - chartInset)
      .innerRadius(radius - chartInset - barWidth);

    const repaintGauge = () => {
      let perc = 75 / 250;
      let next_start = totalPercent;
      let arcStartRad = percToRad(next_start);
      let arcEndRad = arcStartRad + percToRad(perc / 3);
      next_start += perc / 3;

      arc1.startAngle(arcStartRad).endAngle(arcEndRad);

      perc = 150 / 250;
      arcStartRad = percToRad(next_start);
      arcEndRad = arcStartRad + percToRad(perc / 3);
      next_start += perc / 3;

      arc2.startAngle(arcStartRad + padRad).endAngle(arcEndRad);

      perc = 150 / 250;
      arcStartRad = percToRad(next_start);
      arcEndRad = arcStartRad + percToRad(perc / 3);
      next_start += perc / 3;

      arc3.startAngle(arcStartRad + padRad).endAngle(arcEndRad);

      chart.select('.chart-first').attr('d', arc1);
      chart.select('.chart-second').attr('d', arc2);
      chart.select('.chart-third').attr('d', arc3);
    };
    repaintGauge();
    let dataset: any = [{ metric: 'value', value: 17 }];

    const texts = svg.selectAll('text').data(dataset).enter();
    texts
      .append('text')
      .text(() => 0)
      .attr('id', 'scale0')
      .attr(
        'transform',
        'translate(' +
          (width + margin.left) / 100 +
          ', ' +
          (height + margin.top) / 2 +
          ')',
      ) //then change the x and y for the translate
      .attr('font-size', 18)
      .attr('font-family', 'Lato, sans-serif')
      .style('fill', '#35353599');
    texts
      .append('text')
      .text(() => GaugeCharts.gaugeMaxValue - 200)
      .attr('id', 'scale5')
      .attr(
        'transform',
        'translate(' +
          (width + margin.left) / 13 +
          ', ' +
          (height + margin.top) / 4 +
          ')',
      ) //then change the x and y for the translate
      .attr('font-size', 18)
      .attr('font-family', 'Lato, sans-serif')
      .style('fill', '#35353599');
    texts
      .append('text')
      .text(() => GaugeCharts.gaugeMaxValue - 100)
      .attr('id', 'scale10')
      .attr(
        'transform',
        'translate(' +
          (width + margin.left - 130) +
          ', ' +
          (height + margin.top) / 9 +
          ')',
      ) //then change the x and y for the translate
      .attr('font-size', 18)
      .attr('font-family', 'Lato, sans-serif')
      .style('fill', '#35353599');
    texts
      .append('text')
      .text(() => GaugeCharts.gaugeMaxValue)
      .attr('id', 'scale20')
      .attr(
        'transform',
        'translate(' +
          (width + margin.left) / 1.05 +
          ', ' +
          (height + margin.top) / 2 +
          ')',
      ) //then change the x and y for the translate
      .attr('font-size', 18)
      .attr('font-family', 'Lato, sans-serif')
      .style('fill', '#35353599');
    const len = width / 3;
    const needleRadius = len / 8;

    const recalcPointerPos = (perc: any) => {
      let centerX, centerY, leftX, leftY, rightX, rightY, thetaRad, topX, topY;
      thetaRad = percToRad(perc / 2);
      centerX = 0;
      centerY = 0;
      topX = centerX - len * Math.cos(thetaRad);
      topY = centerY - len * Math.sin(thetaRad);
      leftX = centerX - needleRadius * Math.cos(thetaRad - Math.PI / 2);
      leftY = centerY - needleRadius * Math.sin(thetaRad - Math.PI / 2);
      rightX = centerX - needleRadius * Math.cos(thetaRad + Math.PI / 2);
      rightY = centerY - needleRadius * Math.sin(thetaRad + Math.PI / 2);
      return (
        'M ' +
        leftX +
        ' ' +
        leftY +
        ' L ' +
        topX +
        ' ' +
        topY +
        ' L ' +
        rightX +
        ' ' +
        rightY
      );
    };
    chart
      .append('circle')
      .attr('class', 'needle-center')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', needleRadius);
    chart
      .append('path')
      .attr('class', 'needle')
      .attr('id', 'client-needle')
      .attr('d', recalcPointerPos.call(needleRadius, 1));

    const moveNeedle = (prog: number) => {
      let self: any;
      self = this;
      el.transition()
        .delay(100)
        .ease(d3.easeQuad)
        .select('.needle')
        .duration(200)
        .tween('reset-progress', function () {
          return function (percentOfPercent) {
            let progress = (1 - percentOfPercent) * 0;
            repaintGauge();
            return d3
              .select(this)
              .attr('d', recalcPointerPos.call(self, progress));
          };
        });
      el.transition()
        .delay(300)
        .ease(d3.easeBounce)
        .duration(1000)
        .select('.needle')
        .tween('progress', function () {
          return function (percentOfPercent) {
            let progress = percentOfPercent * prog;
            repaintGauge();
            return d3
              .select(this)
              .attr('d', recalcPointerPos.call(self, progress));
          };
        });
    };
    moveNeedle(pressureValue / GaugeCharts.gaugeMaxValue);
  };

  render() {
    return (
      <div
        ref={this._gaugeRef}
        className="chart-gauge"
        style={{ margin: '0 auto' }}
      />
    );
  }
}
export default GaugeCharts;
