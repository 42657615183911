import axios from 'axios';
import { IRequestOption } from 'types/api';

export default async function fetch<Params, Result>(
  url: string,
  options: IRequestOption,
  params?: Params,
): Promise<Result> {
  return new Promise((resolve, reject) => {
    axios({ url, params, ...options })
      .then((res: any) => {
        resolve(res.data);
      })
      .catch((err: any) => {
        const defaultError = {
          code: 500,
          status: 'error',
          message: 'Failed to fetch data. Please contact developer.',
        };

        if (typeof err.response === 'undefined') reject(defaultError);
        else if (typeof err.response.data === 'undefined') reject(defaultError);
        else reject(err.response.data);
      });
  });
}
