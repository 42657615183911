import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const BarChart = createIcon({
  displayName: 'BarChart',
  viewBox: '0 0 32 32',
  defaultProps: {
    w: '32px',
    h: '32px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M28.25 24.75H6.75V5.25C6.75 5.1125 6.6375 5 6.5 5H4.75C4.6125 5 4.5 5.1125 4.5 5.25V26.75C4.5 26.8875 4.6125 27 4.75 27H28.25C28.3875 27 28.5 26.8875 28.5 26.75V25C28.5 24.8625 28.3875 24.75 28.25 24.75ZM9.5 22.25H11.25C11.3875 22.25 11.5 22.1375 11.5 22V17.5C11.5 17.3625 11.3875 17.25 11.25 17.25H9.5C9.3625 17.25 9.25 17.3625 9.25 17.5V22C9.25 22.1375 9.3625 22.25 9.5 22.25ZM14.25 22.25H16C16.1375 22.25 16.25 22.1375 16.25 22V12C16.25 11.8625 16.1375 11.75 16 11.75H14.25C14.1125 11.75 14 11.8625 14 12V22C14 22.1375 14.1125 22.25 14.25 22.25ZM19 22.25H20.75C20.8875 22.25 21 22.1375 21 22V14.4375C21 14.3 20.8875 14.1875 20.75 14.1875H19C18.8625 14.1875 18.75 14.3 18.75 14.4375V22C18.75 22.1375 18.8625 22.25 19 22.25ZM23.75 22.25H25.5C25.6375 22.25 25.75 22.1375 25.75 22V9.5C25.75 9.3625 25.6375 9.25 25.5 9.25H23.75C23.6125 9.25 23.5 9.3625 23.5 9.5V22C23.5 22.1375 23.6125 22.25 23.75 22.25Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
  ]),
});
