/* eslint-disable @typescript-eslint/naming-convention */
import textStyles from 'features/theme/foundations/textStyles';

const FormLabel = {
  baseStyle: {
    ...textStyles.caption,
    color: 'labelLightSecondary',
    position: 'absolute',
    top: '0',
    left: '0.875rem',
    zIndex: '2',
    bgColor: 'bgLightPrimary',
    px: '4px',
    display: 'none',
    transition: 'all .3s',

    _focus: {
      top: '-0.375rem',
      color: 'primary.500',
      display: 'inline-block',
      transition: 'all .3s',
    },

    _invalid: {
      color: 'red.500',
    },
  },
};

export default FormLabel;
