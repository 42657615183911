import { Box, Button, Text, VStack } from '@chakra-ui/react';
import textStyles from 'features/theme/foundations/textStyles';
import useGlobalContext from 'hooks/useGlobalContext';
import moment from 'moment';
import React, { useEffect } from 'react';
import {
  ACTION_TYPES,
  ActionType,
  IAction,
  IAnalysisTimeRange,
} from 'types/context';

import {
  DateTimePickerComponent,
  IDateTimePickerChild,
  IRelativeTIme,
} from './DateTimePicker.types';

const menu: IRelativeTIme[] = [
  {
    value: 5,
    unit: 'minute',
  },
  {
    value: 15,
    unit: 'minute',
  },
  {
    value: 30,
    unit: 'minute',
  },
  {
    value: 1,
    unit: 'hour',
  },
  {
    value: 6,
    unit: 'hour',
  },
];

export function RelativeTimePicker(props: IDateTimePickerChild) {
  const {
    start,
    end,
    stateChanger,
    onStartChange,
    onEndChange,
    changeStateChanger,
    onApply,
  } = props;

  const {
    dispatch,
    state: {
      analysis: { filter },
    },
  } = useGlobalContext();

  const currentComponent: DateTimePickerComponent = 'RelativeTimePicker';
  const initialSelect = filter.isRelative ? filter.relativeProps : undefined;
  const [selected, setSelected] = React.useState<IRelativeTIme | undefined>(
    initialSelect,
  );

  function changeSelected(selected: IRelativeTIme) {
    changeStateChanger(currentComponent);
    setSelected(selected);

    const startDate = moment().subtract(selected.value, selected.unit).toDate();
    const endDate = moment().toDate();
    onStartChange(startDate);
    onEndChange(endDate);
  }

  function setRelativeFilter(filter: IAnalysisTimeRange) {
    const action: IAction<IAnalysisTimeRange> = {
      type: ACTION_TYPES.SET_ANALYSIS_TIME_RANGE as ActionType,
      payload: filter,
    };
    dispatch(action);
  }

  // clear selection if the date is changed from outside
  useEffect(() => {
    if (stateChanger === currentComponent) return;

    if (stateChanger === 'DateTimePicker') return;

    // console.warn('cleared by', stateChanger);

    setSelected(undefined);
    setRelativeFilter({
      isRelative: false,
      relativeProps: undefined,
    });
  }, [start, end]);

  return (
    <Box
      backgroundColor="white"
      width={180}
      p="5"
      display="flex"
      flexDir="column"
    >
      <VStack alignItems="start" spacing={4} flex={1}>
        <Text textStyle="menu">Relative Time Range</Text>
        {menu.map((item) => {
          const { unit, value } = item;
          const label = 'Last ' + value + ' ' + unit;
          const isSelected =
            selected?.unit === unit && selected?.value === value;
          return (
            <Button
              key={label}
              variant="link"
              {...textStyles.body2}
              color={isSelected ? 'blue.500' : 'labelLightSecondary'}
              onClick={() => changeSelected(item)}
              p="1"
            >
              {label}
            </Button>
          );
        })}
      </VStack>
      <Button
        variant="default"
        colorScheme="blue"
        w="full"
        onClick={() => {
          changeStateChanger('DateTimePicker');
          if (selected) {
            setRelativeFilter({
              isRelative: true,
              relativeProps: {
                unit: selected.unit,
                value: selected.value,
              },
            });
          }

          onApply();
        }}
      >
        Apply Filter
      </Button>
    </Box>
  );
}

export default RelativeTimePicker;
