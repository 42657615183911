import { Box, Flex } from '@chakra-ui/react';
import { ROUTE } from 'constants/route';
import { Header, Sidebar } from 'features/common/components';
import ProtectedRoute from 'features/common/components/ProtectedRoute/ProtectedRouter.component';
import useGlobalContext from 'hooks/useGlobalContext';
import React, { useState } from 'react';
import { useFullScreenHandle } from 'react-full-screen';
import { useHistory } from 'react-router';
import { profileStorage } from 'utils/storage';

import { TrackerHomePage } from '..';

export function TrackerPage(): JSX.Element {
  const [activeLink, setActiveLink] = useState(ROUTE.ASSET_TRACKER);
  const [hasNotification, setHasNotification] = useState(false);
  const history = useHistory();
  const {
    state: { profile },
  } = useGlobalContext();

  const handleMenuClick = (path: string) => {
    setActiveLink(path);
    history.push(path);
  };

  const handleOpenFullScreen = () => {
    useFullScreenHandle();
  };

  return (
    <Flex bgColor="bgLightSecondary" zIndex="1000">
      <Box position="fixed" zIndex={1000}>
        <Sidebar
          onMenuClick={(path: string) => handleMenuClick(path)}
          activeLink={activeLink}
        />
      </Box>

      <Box flex={1}>
        <Header
          onFullScreen={handleOpenFullScreen}
          userData={profile ?? profileStorage.get()}
          hasNotification={hasNotification}
        />

        <ProtectedRoute
          path={ROUTE.ASSET_TRACKER}
          isAuthenticated={true}
          component={TrackerHomePage}
        />
      </Box>
    </Flex>
  );
}

export default TrackerPage;
