import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const BatteryIcon = createIcon({
  displayName: 'BatteryIcon',
  viewBox: '0 0 23 23',
  defaultProps: {
    w: '23px',
    h: '23px',
    fill: 'none',
  },
  path: addKeys([
    <g clipPath="url(#clip0_445_18036)">
      <path d="M3.25 8.25H10.125V13.75H3.25V8.25Z" fill="#1D68B8" />
      <path
        d="M3.25 5.5C2.52065 5.5 1.82118 5.78973 1.30546 6.30546C0.789731 6.82118 0.5 7.52065 0.5 8.25L0.5 13.75C0.5 14.4793 0.789731 15.1788 1.30546 15.6945C1.82118 16.2103 2.52065 16.5 3.25 16.5H17C17.7293 16.5 18.4288 16.2103 18.9445 15.6945C19.4603 15.1788 19.75 14.4793 19.75 13.75V8.25C19.75 7.52065 19.4603 6.82118 18.9445 6.30546C18.4288 5.78973 17.7293 5.5 17 5.5H3.25ZM17 6.875C17.3647 6.875 17.7144 7.01987 17.9723 7.27773C18.2301 7.53559 18.375 7.88533 18.375 8.25V13.75C18.375 14.1147 18.2301 14.4644 17.9723 14.7223C17.7144 14.9801 17.3647 15.125 17 15.125H3.25C2.88533 15.125 2.53559 14.9801 2.27773 14.7223C2.01987 14.4644 1.875 14.1147 1.875 13.75V8.25C1.875 7.88533 2.01987 7.53559 2.27773 7.27773C2.53559 7.01987 2.88533 6.875 3.25 6.875H17ZM22.5 11C22.5 11.547 22.2827 12.0716 21.8959 12.4584C21.5091 12.8452 20.9845 13.0625 20.4375 13.0625V8.9375C20.9845 8.9375 21.5091 9.1548 21.8959 9.54159C22.2827 9.92839 22.5 10.453 22.5 11Z"
        fill="#1D68B8"
      />
    </g>,
    <defs>
      <clipPath id="clip0_445_18036">
        <rect width="22" height="22" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>,
  ]),
});
