import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const SunIcon = createIcon({
  displayName: 'SunIcon',
  viewBox: '0 0 23 23',
  defaultProps: {
    w: '23px',
    h: '23px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M6.9098 10.9997C6.9098 13.5306 8.96864 15.5894 11.4996 15.5894C14.0305 15.5894 16.0893 13.5306 16.0893 10.9997C16.0893 8.46876 14.0305 6.40992 11.4996 6.40992C8.96864 6.40992 6.9098 8.46876 6.9098 10.9997ZM11.4996 8.24326C13.0194 8.24326 14.256 9.47984 14.256 10.9997C14.256 12.5195 13.0194 13.7561 11.4996 13.7561C9.97972 13.7561 8.74314 12.5195 8.74314 10.9997C8.74314 9.47984 9.97972 8.24326 11.4996 8.24326ZM10.5811 17.4163H12.4144V20.1663H10.5811V17.4163ZM10.5811 1.83301H12.4144V4.58301H10.5811V1.83301ZM2.33105 10.083H5.08105V11.9163H2.33105V10.083ZM17.9144 10.083H20.6644V11.9163H17.9144V10.083ZM4.36697 16.8324L6.3103 14.8873L7.60739 16.1834L5.66405 18.1286L4.36697 16.8324ZM15.3862 5.81501L17.3314 3.86984L18.6276 5.16601L16.6824 7.11118L15.3862 5.81501ZM6.31305 7.11209L4.36789 5.16692L5.66497 3.87076L7.6083 5.81592L6.31305 7.11209ZM18.6276 16.8333L17.3314 18.1295L15.3862 16.1843L16.6824 14.8882L18.6276 16.8333Z"
      fill="#1D68B8"
    />,
  ]),
});
