import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { gaugeStatus } from 'features/dashboard/constant';
import { LatLngTuple } from 'leaflet';
import React from 'react';

import { IPropTypes } from './AssetTrackerCardItem.types';

export function AssetTrackerCardItem(props: IPropTypes): JSX.Element {
  const { device, changeLocation } = props;
  const { name, location, pressure_value, status, latitude, longitude } =
    device;

  const deviceStatus = gaugeStatus.findIndex((item) => item.name == status);
  const coordinate: LatLngTuple = [latitude, longitude];

  return (
    <VStack align="start">
      <Flex alignItems="center">
        <Text
          onClick={() => {
            changeLocation(coordinate);
          }}
          textStyle="subheading3"
          style={{ cursor: 'pointer' }}
        >
          {name}
        </Text>
        <Box
          ml="2"
          w="1rem"
          h="1rem"
          borderRadius="full"
          bgColor={gaugeStatus[deviceStatus]?.color}
        />
      </Flex>
      <Text textStyle="body2" color="labelLightSecondary">
        {location}
      </Text>
      <Text textStyle="body2" color="labelLightSecondary">
        Pressure: {pressure_value} inH2O
      </Text>
    </VStack>
  );
}

export default AssetTrackerCardItem;
