import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  Spacer,
  VStack,
} from '@chakra-ui/react';
import { Help } from 'features/common/assets';
import Logo from 'features/common/assets/telemetric-logo-blue.svg';
import { menuList } from 'features/common/constant';
import { IMenuList } from 'features/common/types';
import React from 'react';

import { IPropTypes } from './Sidebar.types';

export function Sidebar(props: IPropTypes): JSX.Element {
  const { activeLink, onMenuClick } = props;

  return (
    <Flex
      flexDirection="column"
      alignItems="center"
      bgColor="bgLightTertiary"
      w="20"
      h="100vh"
      py={6}
    >
      <Image src={Logo} w="38px" h="36px" />

      <Box mb={14} />

      <VStack alignItems="stretch">
        {menuList.map((menu: IMenuList, index: number) => (
          <Button
            key={index}
            isActive={activeLink === menu.path}
            variant="menu"
            onClick={() => onMenuClick(menu.path)}
          >
            {menu.icon}
          </Button>
        ))}
      </VStack>

      <Spacer />

      <Link>
        <Help />
      </Link>
    </Flex>
  );
}

export default Sidebar;
