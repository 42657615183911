/* eslint-disable @typescript-eslint/naming-convention */
interface IStatus {
  name: string;
  color: string;
}

export const gaugeStatus: Array<IStatus> = [
  {
    name: 'ONLINE',
    color: 'tintGreen',
  },
  {
    name: 'OFFLINE',
    color: 'tintRed',
  },
];
