import { IDevice } from '../pages/DashboardHomePage/DashboardHomePage.types';
import { EGaugeStatus } from '../types';

export const gaugeMocks: IDevice = {
  battery_current: 10,
  battery_level: 50,
  battery_voltage: 20,
  created_at: '2021-12-15T03:23:54.840Z',
  device_code: '123456789',
  device_name: 'Mitra Kasih Zone 1',
  device_type: 'Jaguar',
  firmware_version: '0.2.1',
  humidity: 40,
  id: 1,
  location: 'Cimahi',
  memory_usage: 60,
  name: 'RS. Mitra Tidak Kasih Zone 1',
  pressure_value: 20,
  solar_current: 30,
  solar_exposure: 20,
  solar_voltage: 40,
  stability_value: 0,
  status: EGaugeStatus.online,
  temperature: 10,
  time_device: '2021-05-27T04:34:23.816Z',
  updated_at: '2021-12-15T03:23:54.840Z',
  volume_value: 150.7,
};
