import { ROUTE } from 'constants/route';
import { BarChart, Compass, Indicator, Settings } from 'features/common/assets';
import { IMenuList } from 'features/common/types';
import React from 'react';

export const menuList: IMenuList[] = [
  {
    id: 0,
    path: ROUTE.DASHBOARD,
    icon: <Indicator />,
  },
  {
    id: 1,
    path: ROUTE.ASSET_TRACKER,
    icon: <Compass />,
  },
  {
    id: 2,
    path: ROUTE.REPORT,
    icon: <BarChart />,
  },
  {
    id: 3,
    path: ROUTE.SETTING,
    icon: <Settings />,
  },
];
