import { Box, VStack } from '@chakra-ui/react';
import { FormikInputPassword } from 'features/common/components';
import React from 'react';

import { IPropTypes } from './NewPasswordForm.types';

export function NewPasswordForm(props: IPropTypes): JSX.Element {
  // const { } = props;

  return (
    <VStack>
      <Box w="100%" mb={8}>
        <FormikInputPassword
          id="newPassword"
          name="newPassword"
          label="password"
          placeholder="Input new password"
        />
      </Box>
      <Box w="100%">
        <FormikInputPassword
          id="confirmNewPassword"
          name="confirmNewPassword"
          label="confirm password"
          placeholder="Confirm new password"
        />
      </Box>
    </VStack>
  );
}

export default NewPasswordForm;
