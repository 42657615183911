import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';

import { IPropTypes } from './List.types';

export function List(props: IPropTypes): JSX.Element {
  const { number, title, subtitle } = props;

  return (
    <Flex
      alignItems="center"
      borderRadius={8}
      bgColor="rgba(211, 230, 250, 0.25)"
      p={4}
    >
      <Box
        borderRadius={8}
        bgColor="primary.100"
        p="0.625rem"
        w="2.75rem"
        h="2.75rem"
        textAlign="center"
      >
        <Text textStyle="subheading2" color="primary.600">
          {number}
        </Text>
      </Box>

      <Box mr="1.625rem" />

      <Flex flexDirection="column">
        <Text textStyle="body1" color="bgElevatedTertiary">
          {title}
        </Text>
        <Text textStyle="body1" color="labelLightSecondary">
          {subtitle}
        </Text>
      </Flex>
    </Flex>
  );
}

export default List;
