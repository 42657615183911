import { ROUTE } from 'constants/route';
import { useHistory } from 'react-router-dom';
import { accessTokenStorage, profileStorage } from 'utils/storage';

import useGlobalContext from './useGlobalContext';

function useLogout(): { logout: () => void } {
  const router = useHistory();
  const { dispatch } = useGlobalContext();

  function logout() {
    accessTokenStorage.remove();
    profileStorage.remove();
    dispatch({ type: 'SET_IS_LOGIN', payload: false });

    router.replace(ROUTE.LOGIN);
  }

  return { logout };
}

export default useLogout;
