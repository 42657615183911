import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { EyeClose, EyeOpen } from 'features/common/assets';
import { Field, FieldProps } from 'formik';
import React, { useState } from 'react';

import { IPropTypes } from './FormikInputPassword.types';

export function FormikInputPassword({
  id,
  label,
  ...props
}: IPropTypes): JSX.Element {
  const [isShow, setIsShow] = useState(false);
  const handleClick = () => setIsShow(!isShow);

  return (
    <Field name={props.name || id}>
      {({ meta, field, form }: FieldProps) => (
        <FormControl
          id={id}
          isInvalid={
            !!(form.dirty && meta.error) || !!(meta.touched && meta.error)
          }
        >
          {label && <FormLabel>{label}</FormLabel>}
          <InputGroup size="md">
            <Input
              {...field}
              {...props}
              type={isShow ? 'text' : 'password'}
              data-testid={id}
              onChange={(e) => {
                form.handleChange(e);
                props.onChange && props.onChange(e);
              }}
            />
            <InputRightElement width="4.5rem">
              <IconButton
                aria-label="Show Password"
                icon={isShow ? <EyeClose /> : <EyeOpen />}
                onClick={() => handleClick()}
                variant="transparent"
              />
            </InputRightElement>
          </InputGroup>
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
}

export default FormikInputPassword;
