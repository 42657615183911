/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import QueryString from 'qs';
import { SWRConfiguration } from 'swr';
import { IRequestOption } from 'types/api';
import fetch from 'utils/fetch';

const createQueryString = (params: any) => {
  const filteredParams = Object.entries(params).reduce(
    (acc: any, [key, value]) => {
      if (value !== '') acc[key] = value;
      return acc;
    },
    {},
  );
  return QueryString.stringify(filteredParams, { arrayFormat: 'brackets' });
};

export const fetcher =
  (options: IRequestOption) =>
  (url: string, params = '{}') => {
    return fetch(url, { ...options, ...JSON.parse(params) });
  };

export const createSwrKey = (url = '', { params = {} } = {}) =>
  `${url}?${createQueryString(params)}`;

export const defaultOptions: SWRConfiguration = {
  refreshInterval: 5000,
  errorRetryInterval: 2500,
};
