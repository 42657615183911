import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const NotifBell = createIcon({
  displayName: 'NotifBell',
  viewBox: '0 0 24 24',
  defaultProps: {
    w: '24px',
    h: '24px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M19.625 18H19.0625V10.0312C19.0625 6.72422 16.618 3.99141 13.4375 3.53672V2.625C13.4375 2.10703 13.018 1.6875 12.5 1.6875C11.982 1.6875 11.5625 2.10703 11.5625 2.625V3.53672C8.38203 3.99141 5.9375 6.72422 5.9375 10.0312V18H5.375C4.96016 18 4.625 18.3352 4.625 18.75V19.5C4.625 19.6031 4.70937 19.6875 4.8125 19.6875H9.875C9.875 21.1359 11.0516 22.3125 12.5 22.3125C13.9484 22.3125 15.125 21.1359 15.125 19.6875H20.1875C20.2906 19.6875 20.375 19.6031 20.375 19.5V18.75C20.375 18.3352 20.0398 18 19.625 18ZM12.5 20.8125C11.8789 20.8125 11.375 20.3086 11.375 19.6875H13.625C13.625 20.3086 13.1211 20.8125 12.5 20.8125ZM7.625 18V10.0312C7.625 8.72813 8.13125 7.50469 9.05234 6.58359C9.97344 5.6625 11.1969 5.15625 12.5 5.15625C13.8031 5.15625 15.0266 5.6625 15.9477 6.58359C16.8687 7.50469 17.375 8.72813 17.375 10.0312V18H7.625Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
  ]),
});
