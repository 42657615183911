import { Box, Center, Flex, HStack, Text, VStack } from '@chakra-ui/react';
import { Card } from 'features/theme/factories';
import colors from 'features/theme/foundations/colors';
import moment from 'moment';
import React from 'react';

import { IPropTypes } from './FillingCard.types';

export function FillingCard(props: IPropTypes): JSX.Element {
  const { fillingData } = props;
  const fillingMetaData = [
    {
      label: 'Level',
      data: {
        initial: fillingData?.initial_level_pressure,
        total: fillingData?.total_level_pressure,
        final: fillingData?.final_level_pressure,
      },
      unit: 'inH20',
    },
    {
      label: 'Volume',
      data: {
        initial: fillingData?.initial_level_volume,
        total: fillingData?.total_level_volume,
        final: fillingData?.final_level_volume,
      },
      unit: 'm3',
    },
    {
      label: 'Weight',
      data: {
        initial: fillingData?.initial_level_weight,
        total: fillingData?.total_level_weight,
        final: fillingData?.final_level_weight,
      },
      unit: 'kg',
    },
  ];
  return (
    <Card p={5}>
      <Text textStyle="heading2" mb={2}>
        Last Filling
      </Text>
      <Text textStyle="caption" color={colors.labelLightSecondary}>
        {`${moment(fillingData.start_date).format(
          'DD MMM yyy hh:mm',
        )} - ${moment(fillingData.finish_date).format('DD MMM yyy hh:mm')}`}
      </Text>
      <HStack pt={3}>
        {fillingMetaData.map((item, i) => (
          <VStack flex={1} key={i}>
            <Box width="100%">
              <Flex
                flexDir="row"
                borderRight={i !== 2 ? '1px' : 0}
                borderRightColor={colors.grey[500]}
                my={4}
                flex={1}
              >
                <Box
                  bg={colors.primary[100]}
                  height={51}
                  width="6px"
                  borderRadius={3}
                  mr={2}
                />
                <Box display="flex" flexDir="column" justifyContent="center">
                  <Text
                    textStyle="caption"
                    color={colors.labelLightSecondary}
                    mb={2}
                  >{`Total ${item.label}`}</Text>
                  <Text textStyle="heading3" color={colors.primary[500]}>
                    {item.data.total} {item.unit}
                  </Text>
                </Box>
              </Flex>
              <Box
                borderRight={i !== 2 ? '1px' : 0}
                borderRightColor={colors.grey[500]}
                pl={3}
                my={4}
              >
                <Text
                  textStyle="caption"
                  color={colors.labelLightSecondary}
                  mb={2}
                >{`Initial ${item.label}`}</Text>
                <Text textStyle="heading3" color={colors.labelLightPrimary}>
                  {item.data.initial} {item.unit}
                </Text>
              </Box>
              <Box
                borderRight={i !== 2 ? '1px' : 0}
                pl={3}
                borderRightColor={colors.grey[500]}
                my={4}
              >
                <Text
                  textStyle="caption"
                  color={colors.labelLightSecondary}
                  mb={2}
                >{`Final ${item.label}`}</Text>
                <Text textStyle="heading3" color={colors.labelLightPrimary}>
                  {item.data.final} {item.unit}
                </Text>
              </Box>
            </Box>
          </VStack>
        ))}
      </HStack>
    </Card>
  );
}

export default FillingCard;
