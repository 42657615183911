/* eslint-disable @typescript-eslint/naming-convention */
import textStyles from 'features/theme/foundations/textStyles';

const Breadcrumb = {
  baseStyle: {
    link: {
      ...textStyles.body2,
      color: 'rgba(53, 53, 53, 0.6)',
    },
  },
};

export default Breadcrumb;
