import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const Calendar = createIcon({
  displayName: 'Calendar',
  viewBox: '0 0 17 16',
  defaultProps: {
    w: '17px',
    h: '16px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M15.6875 1.59375H12.4062V0.34375C12.4062 0.257812 12.3359 0.1875 12.25 0.1875H11.1562C11.0703 0.1875 11 0.257812 11 0.34375V1.59375H6V0.34375C6 0.257812 5.92969 0.1875 5.84375 0.1875H4.75C4.66406 0.1875 4.59375 0.257812 4.59375 0.34375V1.59375H1.3125C0.966797 1.59375 0.6875 1.87305 0.6875 2.21875V15.1875C0.6875 15.5332 0.966797 15.8125 1.3125 15.8125H15.6875C16.0332 15.8125 16.3125 15.5332 16.3125 15.1875V2.21875C16.3125 1.87305 16.0332 1.59375 15.6875 1.59375ZM14.9062 14.4062H2.09375V6.98438H14.9062V14.4062ZM2.09375 5.65625V3H4.59375V3.9375C4.59375 4.02344 4.66406 4.09375 4.75 4.09375H5.84375C5.92969 4.09375 6 4.02344 6 3.9375V3H11V3.9375C11 4.02344 11.0703 4.09375 11.1562 4.09375H12.25C12.3359 4.09375 12.4062 4.02344 12.4062 3.9375V3H14.9062V5.65625H2.09375Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
  ]),
});
