import {
  Box,
  HStack,
  NumberInput,
  NumberInputField,
  Select,
  Text,
} from '@chakra-ui/react';
import { Dropdown } from 'features/common/assets';
import textStyles from 'features/theme/foundations/textStyles';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getHourByMeridiem } from 'utils/date';

import {
  DateTimePickerComponent,
  IDateTime,
  IDateTimePickerChild,
} from './DateTimePicker.types';

function Separator() {
  return (
    <Text color="labelLightSecondary" textStyle="caption">
      :
    </Text>
  );
}

function InputTime(props: IDateTime) {
  const { datetime, stateChanger, onChange, changeStateChanger } = props;
  const [timeMoment, setTimeMoment] = useState(moment(datetime));
  const currentComponent: DateTimePickerComponent = 'TimePicker';

  const [time, setTime] = useState({
    hour: timeMoment.format('hh'),
    minute: timeMoment.format('mm'),
    second: timeMoment.format('ss'),
    ampm: timeMoment.format('A'),
  });

  function updateTime() {
    const date = moment(datetime);
    date.set({
      hour: getHourByMeridiem(time.ampm, parseInt(time.hour)),
      minute: parseInt(time.minute),
      second: parseInt(time.second),
    });

    onChange(date.toDate());
  }

  useEffect(() => {
    if (stateChanger !== currentComponent) return;
    updateTime();
  }, [time]);

  useEffect(() => {
    if (stateChanger === currentComponent) return;
    setTimeMoment(moment(datetime));
  }, [datetime]);

  useEffect(() => {
    setTime({
      hour: timeMoment.format('hh'),
      minute: timeMoment.format('mm'),
      second: timeMoment.format('ss'),
      ampm: timeMoment.format('A'),
    });
  }, [timeMoment]);

  function input(type: 'hour' | 'minute' | 'second') {
    return (
      <NumberInput
        h="3.5"
        variant="unstyled"
        min={0}
        max={type === 'hour' ? 12 : 59}
        value={time[type]}
        clampValueOnBlur={true}
        onBlur={() => {
          if (time[type] === '') {
            setTime({ ...time, [type]: '00' });
          }
        }}
        onChange={(e) => {
          changeStateChanger(currentComponent);
          setTime({ ...time, [type]: e });
        }}
      >
        <NumberInputField
          textAlign="center"
          paddingX="0"
          w="5"
          {...textStyles.caption}
          color="labelLightSecondary"
        />
      </NumberInput>
    );
  }

  return (
    <Box display="flex" flexDir="row" gridGap="2.5" flexGrow={1} marginLeft={4}>
      <Box
        border="1px solid #D1D1D6"
        borderRadius="lg"
        paddingY="3"
        paddingX="4"
      >
        <HStack justifyContent="center">
          {input('hour')}
          <Separator />
          {input('minute')}
          <Separator />
          {input('second')}
        </HStack>
      </Box>
      <Select
        borderRadius="lg"
        bg="bgLightTertiary"
        cursor="pointer"
        icon={<Dropdown />}
        color="labelLightSecondary"
        w="20"
        {...textStyles.caption}
        value={time.ampm}
        onChange={(e) => {
          changeStateChanger(currentComponent);
          setTime({ ...time, ampm: e.target.value });
        }}
      >
        <option value="AM">AM</option>
        <option value="PM">PM</option>
      </Select>
    </Box>
  );
}

function TimePicker(props: IDateTimePickerChild) {
  const { start, end, onStartChange, onEndChange } = props;

  return (
    <Box
      width="full"
      backgroundColor="white"
      display="flex"
      flexDir="row"
      paddingBottom="8"
    >
      <InputTime
        key="inputStart"
        datetime={start}
        onChange={onStartChange}
        {...props}
      />
      <InputTime
        key="inputEnd"
        datetime={end}
        onChange={onEndChange}
        {...props}
      />
    </Box>
  );
}
export default TimePicker;
