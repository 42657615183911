import { IAreaChartItem } from 'features/dashboard/components/AreaChart/AreaChart.types';
import moment from 'moment';

import {
  NodesGraphRequest,
  PressureVolume,
  VolumeRate,
} from './AnalysisPage.types';

const chartXAxisDateFormat = 'DD/MM HH:mm';

export function mapPressureValueToChartItem(
  item: PressureVolume,
): IAreaChartItem {
  return {
    name: moment(item.time_device).format(chartXAxisDateFormat),
    value: item.pressure_value,
  };
}

export function mapVolumeValueToChartItem(
  item: PressureVolume,
): IAreaChartItem {
  return {
    name: moment(item.time_device).format(chartXAxisDateFormat),
    value: item.volume_value,
  };
}

export function mapVolumeRateToChartItem(item: VolumeRate): IAreaChartItem {
  return {
    name: moment(item.time_device).format(chartXAxisDateFormat),
    value: item.volume_rate_value,
  };
}

// CSV Mapper
export function mapChartItemsToCSVData(
  dataName: string,
  chartItems: IAreaChartItem[],
) {
  const data = chartItems.map((item, idx) => [idx + 1, item.name, item.value]);
  return [['No.', 'Time', dataName], ...data];
}

export function mapDateTimeRange(startDate: Date, endDate: Date) {
  const start = moment(startDate).format('DD MMM YYYY HH:mm:ss');
  const end = moment(endDate).format('DD MMM YYYY HH:mm:ss');
  return `${start} - ${end}`;
}

export function mapFilterDateToRequestDate(
  startDate: Date,
  endDate: Date,
): NodesGraphRequest {
  return {
    start_date: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
    end_date: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
  };
}
