import React, { createContext, Dispatch, ReactNode, useReducer } from 'react';
import { ACTION_TYPES, IAction, IState } from 'types/context';

const initialState: IState = {
  isDarkMode: false,
  isLogin: false,
  profile: {
    deviceCode: '',
    email: '',
    fullname: '',
    organization: '',
    role: '',
    avatarUrl: '',
    theme: 'light',
  },
  analysis: {
    filter: {
      isRelative: false,
    },
  },
  report: {
    filter: {
      isRelative: false,
    },
  },
};

const GlobalContext = createContext<{
  state: IState;
  dispatch: Dispatch<IAction>;
}>({
  state: initialState,
  dispatch: () => undefined,
});

function reducer(state: IState, action: IAction): IState {
  switch (action.type) {
    case ACTION_TYPES.SET_IS_LOGIN:
      return { ...state, isLogin: action.payload };
    case ACTION_TYPES.SET_PROFILE: {
      return {
        ...state,
        profile: action.payload,
        isLogin: true,
      };
    }
    case ACTION_TYPES.SET_ANALYSIS_TIME_RANGE: {
      return {
        ...state,
        analysis: {
          filter: { ...action.payload },
        },
      };
    }
    case ACTION_TYPES.SET_REPORT_TIME_RANGE: {
      return {
        ...state,
        report: {
          filter: { ...action.payload },
        },
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function GlobalProvider({
  children,
  initialState,
}: {
  children: ReactNode;
  initialState: IState;
}): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch, ACTION_TYPES };
  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

export { GlobalProvider, GlobalContext };
