const colors = {
  primary: {
    100: '#D3E6FA',
    200: '#A7CDF5',
    300: '#7CB4F0',
    400: '#509BEB',
    500: '#2482E6',
    600: '#1D68B8',
    700: '#164E8A',
    800: '#0E345C',
    900: '#071A2E',
  },
  secondary: {
    100: '#D4EEFB',
    200: '#AADDF6',
    300: '#7FCDF2',
    400: '#55BCED',
    500: '#2AABE9',
    600: '#2289BA',
    700: '#19678C',
    800: '#11445D',
    900: '#08222F',
  },
  red: {
    100: '#FCDAE2',
    200: '#F9B5C5',
    300: '#F591A9',
    400: '#F26C8C',
    500: '#EF476F',
    600: '#BF3959',
    700: '#8F2B43',
    800: '#601C2C',
    900: '#300E16',
  },
  grey: {
    100: '#8E8E93',
    200: '#AEAEB2',
    300: '#C7C7CC',
    400: '#D1D1D6',
    500: '#E5E5EA',
    600: '#F2F2F7',
  },
  tintGreen: '#34C759',
  tintGreenBase: 'rgba(52, 199, 89, 0.2)',
  tintRed: 'rgba(246, 93, 84, 1)',
  tintRedBase: 'rgba(246, 93, 84, 0.2)',
  bgDarkPrimary: '#1C1C1E',
  bgDarkSecondary: '#2C2C2E',
  bgDarkTertiary: '#3A3A3C',
  bgElevatedPrimary: '#2C2C2E',
  bgElevatedSecondary: '#1C1C1E',
  bgElevatedTertiary: '#000000',
  bgElevatedQuertenary: '#303032',
  bgLightPrimary: '#FFFFFF',
  bgLightSecondary: '#F7F8FA',
  bgLightTertiary: '#F2F2F7',
  bgLightQuartenary: '#F6F6F9',
  labelDarkPrimary: '#F2F2F7',
  labelDarkSecondary: 'rgba(242, 242, 247, 0.6)',
  labelDarkTertiary: 'rgba(242, 242, 247, 0.3)',
  labelLightPrimary: '#353535',
  labelLightSecondary: 'rgba(53, 53, 53, 0.6)',
  labelLightTertiary: 'rgba(53, 53, 53, 0.3)',
  overlay: 'rgba(255, 255, 255, 0.38)',
};

export default colors;
