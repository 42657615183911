import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const Switch = createIcon({
  displayName: 'Switch',
  viewBox: '0 0 24 24',
  defaultProps: {
    w: '24px',
    h: '24px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M8 7H20H8ZM20 7L16 3L20 7ZM20 7L16 11L20 7ZM16 17H4H16ZM4 17L8 21L4 17ZM4 17L8 13L4 17Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
    <path
      d="M4 17L8 13M8 7H20H8ZM20 7L16 3L20 7ZM20 7L16 11L20 7ZM16 17H4H16ZM4 17L8 21L4 17Z"
      stroke="#353535"
      strokeOpacity="0.6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />,
  ]),
});
