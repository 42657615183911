import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './style.css';

import {
  Box,
  Divider,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { DateRangePicker, Range, RangeKeyDict } from 'react-date-range';

import {
  DateTimePickerComponent,
  IDateTimePicker,
  IDateTimePickerModal,
} from './DateTimePicker.types';
import RelativeTimePicker from './RelativeTimePicker.component';
import TimePicker from './TimePicker.component';

export function DateTimePicker(props: IDateTimePicker & IDateTimePickerModal) {
  const { start, end, isOpen, onStartChange, onEndChange, onClose } = props;
  const range: Range = {
    startDate: start,
    endDate: end,
    key: 'selection',
  };

  const currentComponent: DateTimePickerComponent = 'DateTimePicker';
  const [stateChanger, setStateChanger] =
    React.useState<DateTimePickerComponent>(currentComponent);

  function onRangeChange(range: RangeKeyDict) {
    const { startDate, endDate } = range.selection;
    if (!startDate || !endDate) return;

    setStateChanger('ThirdPartyDateTimePicker');
    onStartChange(startDate);
    onEndChange(endDate);
  }

  useEffect(() => {
    setStateChanger(currentComponent);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalBody>
        <ModalContent display="flex" flexDir="row" borderRadius="md" p={5}>
          <Box>
            <DateRangePicker
              months={2}
              direction="horizontal"
              ranges={[range]}
              inputRanges={[]}
              staticRanges={[]}
              headerContent={null}
              footerContent={null}
              showDateDisplay={false}
              onChange={onRangeChange}
              preventSnapRefocus={true}
            />
            <TimePicker
              {...props}
              stateChanger={stateChanger}
              changeStateChanger={setStateChanger}
            />
          </Box>
          <Divider w="0.5" />
          <RelativeTimePicker
            {...props}
            stateChanger={stateChanger}
            changeStateChanger={setStateChanger}
          />
        </ModalContent>
      </ModalBody>
    </Modal>
  );
}

export default DateTimePicker;
