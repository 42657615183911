import ENDPOINT from 'constants/endpoint';
import {
  IThresholdForm,
  IThresholdRequest,
} from 'features/dashboard/components/ThresholdSettingModal/ThresholdModal.types';
import { fetcher } from 'hooks/useData/utils';
import useSwr from 'swr';
import { IBaseModel, IRequestOption } from 'types/api';
import fetch from 'utils/fetch';
import { accessTokenStorage } from 'utils/storage';

export async function setThreshold(
  body: IThresholdForm,
): Promise<IBaseModel<IThresholdForm>> {
  // TODO: change to backend
  const deviceCode = '123456789';

  const bodyRequest: IThresholdRequest = {
    low_limit: parseInt(body.low_threshold),
    up_limit: parseInt(body.high_threshold),
    low_limit_hospital: parseInt(body.hospital_low_threshold),
    up_limit_hospital: parseInt(body.hospital_high_threshold),
  };

  const options: IRequestOption = {
    method: 'PUT',
    data: bodyRequest,
    headers: {
      Authorization: `Bearer ${accessTokenStorage.get().value}`,
    },
  };

  try {
    const response = await fetch<typeof body, IBaseModel<IThresholdForm>>(
      ENDPOINT.THRESHOLD(deviceCode),
      options,
    );

    if (response.code !== 200) {
      throw new Error(response.message);
    }

    return Promise.resolve(response);
  } catch (error) {
    return Promise.reject(error);
  }
}

export function getThreshold() {
  const deviceCode = '123456789'; // TODO: use real deviceCode
  const { data, error } = useSwr<any, any>(
    ENDPOINT.THRESHOLD(deviceCode),
    fetcher({
      method: 'GET',
      headers: { Authorization: `bearer ${accessTokenStorage.get().value}` },
    }),
  );

  return { data, error };
}
