import { NodesGraphRequest } from 'features/dashboard/pages/AnalysisPage/AnalysisPage.types';
import moment from 'moment';

export function mapDateTimeRange(startDate: Date, endDate: Date): string {
  const start = moment(startDate).format('DD MMM YYYY HH:mm:ss');
  const end = moment(endDate).format('DD MMM YYYY HH:mm:ss');
  return `${start} - ${end}`;
}

export function mapFilterDateToRequestDate(
  startDate: Date,
  endDate: Date,
): NodesGraphRequest {
  return {
    start_date: moment(startDate).format('YYYY-MM-DD HH:mm:ss'),
    end_date: moment(endDate).format('YYYY-MM-DD HH:mm:ss'),
  };
}
