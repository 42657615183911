import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
} from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';
import React from 'react';

import { IPropTypes } from './FormikInput.types';

export function FormikInput({
  id,
  label,
  labelAlwaysActive,
  ...props
}: IPropTypes): JSX.Element {
  return (
    <Field name={props.name || id}>
      {({ meta, field, form }: FieldProps) => (
        <FormControl
          id={id}
          isInvalid={
            !!(form.dirty && meta.error) || !!(meta.touched && meta.error)
          }
        >
          {label && (
            <FormLabel
              top={labelAlwaysActive ? '-0.375rem' : '0'}
              display={labelAlwaysActive ? 'inline-block' : 'none'}
            >
              {label}
            </FormLabel>
          )}
          <Input
            {...field}
            {...props}
            variant="outline"
            data-testid={id}
            onChange={(e) => {
              form.handleChange(e);
              props.onChange && props.onChange(e);
            }}
          />
          <FormErrorMessage>{meta.error}</FormErrorMessage>
        </FormControl>
      )}
    </Field>
  );
}

export function FormikInputRightLabel({
  id,
  label,
  labelAlwaysActive,
  ...props
}: IPropTypes): JSX.Element {
  return (
    <Field name={props.name || id}>
      {({ meta, field, form }: FieldProps) => (
        <InputGroup size="md">
          <FormControl
            id={id}
            isInvalid={
              !!(form.dirty && meta.error) || !!(meta.touched && meta.error)
            }
          >
            {label && (
              <FormLabel
                top={labelAlwaysActive ? '-0.375rem' : '0'}
                display={labelAlwaysActive ? 'inline-block' : 'none'}
              >
                {label}
              </FormLabel>
            )}
            <Input
              {...field}
              {...props}
              variant="outline"
              data-testid={id}
              onChange={(e) => {
                form.handleChange(e);
                props.onChange && props.onChange(e);
              }}
            />
            <FormErrorMessage>{meta.error}</FormErrorMessage>
          </FormControl>
          {/* eslint-disable react/no-children-prop */}
          <InputRightElement
            pointerEvents="none"
            width="4.5rem"
            children={
              <Text textStyle="body2" color="labelLightSecondary">
                inH20
              </Text>
            }
          />
        </InputGroup>
      )}
    </Field>
  );
}

export default FormikInput;
