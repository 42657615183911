import { Box, Flex, Grid, GridItem, Text, VStack } from '@chakra-ui/react';
import { List } from 'features/common/components';
import { GaugeCharts } from 'features/dashboard/components';
import { EGaugeType } from 'features/dashboard/types';
import { Card } from 'features/theme/factories';
import { useNodes } from 'hooks/useData/useNode';
import useGlobalContext from 'hooks/useGlobalContext';
import React from 'react';
import { Link } from 'react-router-dom';

import { IDevice } from './DashboardHomePage.types';

export function DashboardHomePage(): JSX.Element {
  const cardHeight = 'calc(100vh - 140px)';
  const {
    state: { profile },
  } = useGlobalContext();

  const { data: responseNodesData } = useNodes(profile?.organization);
  const nodesData = responseNodesData?.data ?? [];

  const renderGaugeChart = (device: IDevice): JSX.Element => (
    <Box key={device.id}>
      <Link to={`/dashboard/nodes/${device.device_code}/analysis`}>
        <GaugeCharts gaugeType={EGaugeType.type1} gaugeData={device} />
      </Link>
    </Box>
  );
  const renderFillingEstimationSchedule = (): JSX.Element => (
    <Box w="100%">
      <Card bgColor="bgLightPrimary" px="6" py="7" h={cardHeight}>
        <Text textStyle="heading2" color="labelLightPrimary" mb={6}>
          Filling Estimation Schedule
        </Text>

        <VStack alignItems="unset" spacing={5}>
          {nodesData.map((device: IDevice, index: number) => (
            <List
              key={device.id}
              number={`${index + 1}`}
              title={device.name}
              subtitle="Estimation 31/08/2021"
            />
          ))}
        </VStack>
      </Card>
    </Box>
  );

  const renderGaugeSection = (): JSX.Element => (
    <Grid templateColumns="repeat(3, 1fr)" gap={8}>
      {nodesData.map((deviceData: IDevice) => {
        return renderGaugeChart(deviceData);
      })}
    </Grid>
  );

  return (
    <Box>
      <Grid templateColumns="repeat(4, 1fr)" gap={5}>
        <GridItem colSpan={3}>
          <Flex flexDirection="column">
            <Text textStyle="title" color="labelLightPrimary" mb="1.625rem">
              Dashboard
            </Text>
            {renderGaugeSection()}
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>{renderFillingEstimationSchedule()}</GridItem>
      </Grid>
    </Box>
  );
}

export default DashboardHomePage;
