import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  StackDivider,
  Text,
} from '@chakra-ui/react';
import { ChevronDown, FullScreen, NotifBell } from 'features/common/assets';
import { useLogout } from 'hooks';
import React from 'react';

import { IPropTypes } from './Header.types';

export function Header(props: IPropTypes): JSX.Element {
  const { onFullScreen, hasNotification = false, userData } = props;
  const { logout } = useLogout();

  const renderProfileDropdown = (): JSX.Element => (
    <Menu autoSelect={false}>
      <MenuButton
        as={Button}
        rightIcon={<ChevronDown ml={8} />}
        bgColor="transparent"
        _hover={{ bgColor: 'transparent' }}
        _focus={{
          bgColor: 'transparent',
          outline: 'none',
          boxShadow: 0,
        }}
        _active={{ bgColor: 'transparent' }}
      >
        <Flex alignItems="center">
          <Box mr={3}>
            {userData.avatarUrl ? (
              <Image
                borderRadius="full"
                w="44px"
                h="44px"
                src={userData.avatarUrl}
                fallbackSrc="https://via.placeholder.com/100x100?text=%20"
                objectFit="cover"
              />
            ) : (
              <Box bgColor="grey.400" w="44px" h="44px" borderRadius="full" />
            )}
          </Box>

          <Flex
            flexDirection="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Text textStyle="menu" color="labelLightPrimary">
              {userData.fullname}
            </Text>
            <Text textStyle="caption" color="labelLightSecondary">
              {userData.role}
            </Text>
          </Flex>
        </Flex>
      </MenuButton>
      <MenuList>
        <MenuItem onClick={logout}>Logout</MenuItem>
      </MenuList>
    </Menu>
  );

  const renderNotifDropdown = (): JSX.Element => (
    <Menu autoSelect={false}>
      <MenuButton
        as={Button}
        bgColor="transparent"
        position="relative"
        _hover={{ bgColor: 'transparent' }}
        _focus={{
          bgColor: 'transparent',
          outline: 'none',
          boxShadow: 0,
        }}
        _active={{ bgColor: 'transparent' }}
      >
        {hasNotification && (
          <Box
            w="8px"
            h="8px"
            position="absolute"
            bgColor="primary.500"
            borderRadius="full"
            right="17px"
            top="10px"
          />
        )}
        <NotifBell />
      </MenuButton>
      <MenuList>
        <MenuItem>Download</MenuItem>
        <MenuItem>Create a Copy</MenuItem>
        <MenuItem>Mark as Draft</MenuItem>
        <MenuItem>Delete</MenuItem>
        <MenuItem>Attend a Workshop</MenuItem>
      </MenuList>
    </Menu>
  );

  return (
    <Flex
      bgColor="bgLightPrimary"
      py="0.875rem"
      px={8}
      position="fixed"
      ml="20"
      w="full"
      h="16"
      alignItems="center"
      zIndex="1000"
    >
      <IconButton
        variant="outline"
        borderColor="grey.400"
        aria-label="Full Screen"
        icon={<FullScreen />}
        onClick={() => onFullScreen()}
      />

      <Spacer />

      <HStack divider={<StackDivider borderColor="grey.500" />}>
        {renderNotifDropdown()}

        {renderProfileDropdown()}
      </HStack>
    </Flex>
  );
}

export default Header;
