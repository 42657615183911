import { Box, Button, Flex, Text, VStack } from '@chakra-ui/react';
import { NewPasswordForm, Wrapper } from 'features/auth/components';
import { INewPassword } from 'features/auth/types';
import { Form, Formik, FormikProps } from 'formik';
import React, { useRef } from 'react';

import { IPropTypes } from './CreateNewPasswordPage.types';

export function CreateNewPasswordPage(props: IPropTypes): JSX.Element {
  // const { } = props;

  const newPassword = useRef<FormikProps<INewPassword>>(null);

  const handleSubmit = (values: INewPassword) => {
    console.warn('this is login', values);
  };

  const renderLeftComponent = (): JSX.Element => (
    <VStack pt="7rem" pb={9} px={9} w="460px" textAlign="center">
      <Flex alignItems="center" flexDirection="column" px={4}>
        <Text textStyle="subtitle" color="labelLightPrimary">
          Create new password
        </Text>

        <Box mb={4} />

        <Text textStyle="body1" color="labelLightSecondary" opacity="0.6">
          Your new password must be different from previous used passwords.
        </Text>
      </Flex>

      <Box h="3.25rem" />

      <Formik
        innerRef={newPassword}
        initialValues={{ newPassword: '', createNewPassword: '' }}
        onSubmit={handleSubmit}
      >
        {({ isValid, submitForm }) => {
          return (
            <Form style={{ width: '100%' }}>
              <NewPasswordForm />

              <Button
                type="submit"
                variant="default"
                onClick={submitForm}
                isDisabled={!isValid}
                mt="4.375rem"
                isFullWidth
              >
                Reset password
              </Button>
            </Form>
          );
        }}
      </Formik>

      <Box h="10.875rem" />
    </VStack>
  );

  return <Wrapper leftComponent={renderLeftComponent()} />;
}

export default CreateNewPasswordPage;
