import { Flex, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react';
import { Card } from 'features/theme/factories';
import React from 'react';

import { IPropTypes } from './Table.types';

export function TableCard(props: IPropTypes): JSX.Element {
  const { data, title, heads } = props;
  return (
    <Flex>
      <Card flex={1}>
        <Text textStyle="heading2" p={5} mb={2}>
          {title}
        </Text>
        <Table variant="simple">
          <Thead>
            <Tr>
              {heads.map((head, i) => (
                <Th key={i}>{head}</Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data?.map((item, i) => (
              <Tr key={i}>
                {Object.keys(item).map((property, keyIndex) => (
                  <Td key={keyIndex}>{item[property]}</Td>
                ))}
              </Tr>
            ))}
            {(!data || !data.length) && (
              <Tr>
                <Td colSpan={heads.length} textAlign="center">
                  No Data
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Card>
    </Flex>
  );
}

export default TableCard;
