import { IFilling } from 'features/dashboard/types';

export const fillingMocks: IFilling = {
  start_date: '2022-06-27T12:34:23.816Z',
  finish_date: '2022-06-27T12:34:23.816Z',
  initial_level_pressure: 0,
  final_level_pressure: 0,
  total_level_pressure: 0,
  initial_level_volume: 0,
  final_level_volume: 0,
  total_level_volume: 0,
  initial_level_weight: 0,
  final_level_weight: 0,
  total_level_weight: 0,
};
