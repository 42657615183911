/* eslint-disable @typescript-eslint/naming-convention */
import textStyles from 'features/theme/foundations/textStyles';

export const Button = {
  variants: {
    default: (): Record<string, any> => ({
      ...textStyles.button,
      bgColor: 'primary.500',
      borderRadius: '0.5rem',
      color: 'bgLightPrimary',
      py: '0.875rem',
      // minWidth: '280px',
      transition: 'background .3s all',

      _disabled: {
        bgColor: 'grey.200',
        color: 'overlay',
      },

      _hover: {
        bgColor: 'primary.400',
        _disabled: {
          bgColor: 'grey.200',
        },
      },

      _focus: {
        bgColor: 'primary.300',
        boxShadow: 0,
      },
    }),
    solid: (): Record<string, any> => ({
      bgColor: 'rgba(242, 242, 247, 0.6)',
      p: '0.5rem 1rem',
      borderRadius: '0.5rem',
    }),
    transparent: (): Record<string, any> => ({
      bgColor: 'transparent',

      _focus: {
        boxShadow: 'none',
      },
    }),
    menu: (): Record<string, any> => ({
      position: 'relative',
      px: '1.25rem',
      py: '0.375rem',
      mb: '2rem !important',
      transition: 'all .5s',
      path: {
        fill: 'labelLightPrimary',
        fillOpacity: '0.6',
      },

      _hover: {
        _after: {
          content: `''`,
          width: '2px',
          height: '100%',
          position: 'absolute',
          bgColor: 'primary.400',
          top: 0,
          right: 0,
        },

        path: {
          fill: 'primary.500',
          fillOpacity: '1',
        },
      },

      _active: {
        _after: {
          content: `''`,
          width: '2px',
          height: '100%',
          position: 'absolute',
          bgColor: 'primary.400',
          top: 0,
          right: 0,
        },

        path: {
          fill: 'primary.500',
          fillOpacity: '1',
        },
      },

      _activeLink: {
        borderRight: '3px solid',
        borderColor: 'primary.400',

        path: {
          fill: 'primary.500',
          fillOpacity: '1',
        },
      },

      _focus: {
        outline: 'none',
        boxShadow: 'none',
      },
    }),
  },
};

export default Button;
