import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const ChevronDown = createIcon({
  displayName: 'ChevronDown',
  viewBox: '0 0 24 24',
  defaultProps: {
    w: '24px',
    h: '24px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M19.2589 8.75107C19.4188 8.90616 19.5054 9.1135 19.4997 9.32752C19.494 9.54153 19.3965 9.74471 19.2286 9.89238L13.104 15.277C12.9413 15.4201 12.7251 15.5 12.5003 15.5C12.2755 15.5 12.0593 15.4201 11.8966 15.277L5.77196 9.89345C5.6038 9.74582 5.50607 9.54258 5.50027 9.32844C5.49448 9.1143 5.58108 8.90681 5.74104 8.75161C5.901 8.59641 6.12121 8.50621 6.35322 8.50086C6.58524 8.49551 6.81005 8.57544 6.97821 8.72308L12.5009 13.5779L18.0235 8.722C18.1917 8.57457 18.4164 8.49481 18.6483 8.50026C18.8802 8.50571 19.1002 8.59593 19.2601 8.75107H19.2589Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
  ]),
});
