const textStyles = {
  bigTitle: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400',
    fontSize: '2.5rem',
    lineHeight: '3rem',
  },
  title: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    fontSize: '2rem',
    lineHeight: '2.25rem',
  },
  subtitle: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    fontSize: '1.75rem',
    lineHeight: '2.125rem',
  },
  heading1: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
  },
  heading2: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
  },
  heading3: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  subheading1: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400',
    fontSize: '1.5rem',
    lineHeight: '1.75rem',
  },
  subheading2: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400',
    fontSize: '1.25rem',
    lineHeight: '1.5rem',
  },
  subheading3: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400',
    fontSize: '1.20rem',
    lineHeight: '1.5rem',
  },
  button: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  menu: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '700',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
  },
  body1: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400',
    fontSize: '1rem',
    lineHeight: '1.25rem',
  },
  body2: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '1.125rem',
  },
  caption: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400',
    fontSize: '0.875rem',
    lineHeight: '0.75rem',
  },
  small: {
    fontFamily: 'Lato, sans-serif',
    fontWeight: '400',
    fontSize: '0.625rem',
    lineHeight: '0.75rem',
  },
};

export default textStyles;
