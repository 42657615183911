import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Wrapper } from 'features/auth/components';
import { EmailVerifyPage } from 'features/auth/pages';
import PadlockImage from 'features/common/assets/Padlock.png';
import React, { useState } from 'react';

import { IPropTypes } from './AuthCodeEntryPage.types';

export function AuthCodeEntryPage(props: IPropTypes): JSX.Element {
  // const { } = props;
  const [isModalOpen, setIsModalOpen] = useState(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const renderLeftComponent = (): JSX.Element => (
    <VStack pt="7rem" pb={9} px="3.625rem" w="460px" textAlign="center">
      <Flex alignItems="center" flexDirection="column" px={10}>
        <Text textStyle="subtitle" color="labelLightPrimary">
          Verify email
        </Text>

        <Box mb={4} />

        <Text textStyle="body1" color="labelLightSecondary" opacity="0.6">
          Please enter the verification code we send to your email
        </Text>
      </Flex>

      <Box h="3.25rem" />

      <Image src={PadlockImage} />

      <Box h={8} />

      <HStack>
        <PinInput placeholder="">
          <PinInputField variant="filled" mr="1rem !important" />
          <PinInputField variant="filled" mr="1rem !important" />
          <PinInputField variant="filled" mr="1rem !important" />
          <PinInputField />
        </PinInput>
      </HStack>

      <Box h={8} />

      <Button variant="default">Submit</Button>

      <Box h="7.25rem" />

      <Text>
        Didn’t receive code?{''}
        <Button variant="transparent" color="primary.500" textStyle="body1">
          Request again
        </Button>
      </Text>
    </VStack>
  );

  const renderModal = (): JSX.Element => (
    <Modal isOpen={isModalOpen} onClose={handleCloseModal} isCentered>
      <ModalOverlay />
      <ModalContent overflow="auto" borderRadius="1rem">
        <ModalCloseButton
          color="rgba(53, 53, 53, 0.6)"
          top="1.5rem"
          right="1.5rem"
        />
        <EmailVerifyPage />
      </ModalContent>
    </Modal>
  );

  return (
    <div>
      <Wrapper leftComponent={renderLeftComponent()} />

      {renderModal()}
    </div>
  );
}

export default AuthCodeEntryPage;
