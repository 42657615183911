import { Box, Flex, Text, VStack } from '@chakra-ui/react';
import { Card } from 'features/theme/factories';
import colors from 'features/theme/foundations/colors';
import moment from 'moment';
import React from 'react';

import { IPropTypes } from './TotalUsageCard.types';

export function TotalUsageCard(props: IPropTypes): JSX.Element {
  const { usageData, periode } = props;
  const { volume_used, weight_used } = usageData;
  return (
    <Card>
      <VStack alignItems="unset">
        <Box borderBottom="1px" borderBottomColor="#E5E5EA" p={5}>
          <Flex flexDirection="column">
            <Flex alignItems="center" mb={2}>
              <Text textStyle="heading2">{`Total ${
                periode === 'daily' ? 'Daily' : 'Monthly'
              } Usage`}</Text>
            </Flex>
            <Text textStyle="caption" color={colors.labelLightSecondary} mb={3}>
              {moment(new Date()).format('DD MMM yyyy')}
            </Text>
          </Flex>
          <Flex flexDirection="column">
            <Text
              textStyle="title"
              color={colors.primary[500]}
              mb={3}
              pt={5}
            >{`${volume_used.toFixed(2)} m`}</Text>
            <Text textStyle="caption" color={colors.labelLightSecondary}>
              Volume Used
            </Text>
          </Flex>
        </Box>
        <Box p={5}>
          <Flex flexDirection="column">
            <Text
              textStyle="title"
              color={colors.primary[500]}
              mb={3}
            >{`${weight_used.toFixed(2)} kg`}</Text>
            <Text pb={3} textStyle="caption" color={colors.labelLightSecondary}>
              Weight Used
            </Text>
          </Flex>
        </Box>
      </VStack>
    </Card>
  );
}

export default TotalUsageCard;
