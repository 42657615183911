import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from '@chakra-ui/breadcrumb';
import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { Box, HStack, Spacer, Text, VStack } from '@chakra-ui/layout';
import { Calendar } from 'features/common/assets/Calendar';
import { PromptModal, Section } from 'features/common/components';
import { AreaChart } from 'features/dashboard/components';
import { DateTimePicker } from 'features/dashboard/components/DateTimePicker';
import { ThresholdModal } from 'features/dashboard/components/ThresholdSettingModal';
import { Card } from 'features/theme/factories';
import { useNodesDetail, useNodesGraph } from 'hooks/useData/useNode';
import useGlobalContext from 'hooks/useGlobalContext';
import useInterval from 'hooks/useInterval';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { IBaseModel } from 'types/api';

import {
  IAnalysisPageParams,
  IExportModalData,
  INodeGraphResponse,
  NodesGraphRequest,
} from './AnalysisPage.types';
import {
  mapChartItemsToCSVData,
  mapDateTimeRange,
  mapFilterDateToRequestDate,
  mapPressureValueToChartItem,
  mapVolumeRateToChartItem,
  mapVolumeValueToChartItem,
} from './mapper';

const BreadcrumbSection = () => {
  return (
    <Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
      <BreadcrumbItem>
        <BreadcrumbLink as={Link} to="/">
          Dashboard
        </BreadcrumbLink>
      </BreadcrumbItem>

      <BreadcrumbItem isCurrentPage>
        <BreadcrumbLink color="labelLightColor">Analysis Page</BreadcrumbLink>
      </BreadcrumbItem>
    </Breadcrumb>
  );
};

export function AnalysisPage(): JSX.Element {
  const { deviceCode }: IAnalysisPageParams = useParams();

  const {
    isOpen: isExportModalOpen,
    onOpen: onExportModalOpen,
    onClose: onExportModalClose,
  } = useDisclosure();
  const {
    isOpen: isThresholdSettingOpen,
    onOpen: onThresholdSettingOpen,
    onClose: onThresholdSettingClose,
  } = useDisclosure();
  const {
    isOpen: isFilterOpen,
    onOpen: onFilterOpen,
    onClose: onFilterClose,
  } = useDisclosure();

  const [modalData, setModalData] = useState<IExportModalData>();

  // datetime filter setup
  const initialStartDate = moment().subtract(2, 'month').toDate();
  const initialEndDate = new Date();
  const [startDate, setStartDate] = useState(initialStartDate);
  const [endDate, setEndDate] = useState(initialEndDate);
  const [filterPlaceholder, setFilterPlaceholder] = useState(
    mapDateTimeRange(startDate, endDate),
  );
  const [requestDataParams, setRequestDataParams] = useState<NodesGraphRequest>(
    mapFilterDateToRequestDate(startDate, endDate),
  );

  const {
    state: {
      analysis: { filter },
    },
  } = useGlobalContext();

  const isSwrPaused = () => {
    return isFilterOpen;
  };

  const {
    data: rawResponse,
    error,
    mutate,
  } = useNodesGraph(deviceCode, requestDataParams, {
    isPaused: isSwrPaused, // prevent rerender because of state change
  });

  const { data: rawDetailResponse } = useNodesDetail(
    deviceCode,
    {
      time_zone: 'Asia/Jakarta',
    },
    {
      isPaused: isSwrPaused, // prevent rerender because of state change
    },
  );

  const name = rawDetailResponse?.data?.device_detail_data.device_name ?? '';

  const response = rawResponse as IBaseModel<INodeGraphResponse>;
  const volumeRates =
    response?.data?.volume_rate_value.map(mapVolumeRateToChartItem).reverse() ??
    [];
  const pressureValues =
    response?.data?.pressure_volume_value
      .map(mapPressureValueToChartItem)
      .reverse() ?? [];
  const volumeValues =
    response?.data?.pressure_volume_value
      .map(mapVolumeValueToChartItem)
      .reverse() ?? [];

  function exportData(props: IExportModalData) {
    setModalData({ ...props });
    onExportModalOpen();
  }

  async function filterData() {
    setRequestDataParams(mapFilterDateToRequestDate(startDate, endDate));
    setFilterPlaceholder(mapDateTimeRange(startDate, endDate));
  }

  useInterval(
    () => {
      if (isFilterOpen) return;
      if (!filter.isRelative || !filter.relativeProps) return;

      const { value, unit } = filter.relativeProps;

      const start = moment().subtract(value, unit).toDate();
      const end = new Date();
      setStartDate(start);
      setEndDate(end);
    },
    filter.isRelative ? 1000 : null,
  );

  useEffect(() => {
    if (!filter.isRelative) return;
    if (isFilterOpen) return;

    filterData();
  }, [startDate]);

  // effect to update swr config on hook state change
  useEffect(() => {
    if (!isFilterOpen) mutate();
  }, [isFilterOpen]);

  return (
    <VStack align="stretch" spacing="6">
      <BreadcrumbSection />
      <HStack>
        <Text textStyle="subtitle">{name}</Text>
        <Spacer />

        <Box display="flex" flexDir="row" gridGap="4">
          <Button
            variant="secondary"
            bg="white"
            color="labelLightSecondary"
            leftIcon={<Calendar />}
            borderColor="gray.500"
            boxShadow="sm"
            onClick={onFilterOpen}
          >
            {filterPlaceholder}
          </Button>
          <Button variant="default" onClick={onThresholdSettingOpen}>
            Threshold Setting
          </Button>
        </Box>
      </HStack>
      <Card p="6">
        <Section
          hasRightComponent
          onButtonExportClick={() =>
            exportData({
              filename: 'level_liquid.csv',
              dataName: 'Level Liquid',
              data: pressureValues,
            })
          }
          title="Level Liquid (inH20)"
        >
          <AreaChart
            data={pressureValues}
            tooltipUnit="inH20"
            stroke="#7CB4F0"
            fill="#7CB4F0"
            dotStroke="#7CB4F0CC"
            activeDotStroke="#2482E6"
          />
        </Section>
      </Card>
      <Card p="6">
        <Section
          hasRightComponent
          onButtonExportClick={() =>
            exportData({
              filename: 'volume_liquid.csv',
              dataName: 'Volume Liquid',
              data: volumeValues,
            })
          }
          title="Volume Liquid (m2)"
        >
          <AreaChart
            data={volumeValues}
            tooltipUnit="m2"
            stroke="#99D7F4"
            fill="#99D7F4"
            dotStroke="#99D7F4"
            activeDotStroke="#2482E6"
          />
        </Section>
      </Card>
      <Card p="6">
        <Section
          hasRightComponent
          onButtonExportClick={() =>
            exportData({
              filename: 'volume_rate.csv',
              dataName: 'Volume Rate',
              data: volumeRates,
            })
          }
          title="Volume Rate (m3/h)"
        >
          <AreaChart
            data={volumeRates}
            tooltipUnit="m3/h"
            stroke="#77A4D4"
            fill="#77A4D4"
            dotStroke="#77A4D4"
            activeDotStroke="#2482E6"
          />
        </Section>
      </Card>
      <PromptModal
        title="Export Excel"
        body={`You are going to export data "${modalData?.dataName ?? ''}"`}
        cancelText="cancel"
        isOpen={isExportModalOpen}
        onClose={onExportModalClose}
        confirmText="confirm"
        onConfirm={() => {
          console.warn('export confirmed!');
          onExportModalClose();
        }}
        isCsvPrint
        csvFilename={modalData?.filename}
        csvData={
          modalData
            ? mapChartItemsToCSVData(modalData.dataName, modalData.data)
            : []
        }
      />
      <ThresholdModal
        isOpen={isThresholdSettingOpen}
        onClose={onThresholdSettingClose}
      />
      <DateTimePicker
        isOpen={isFilterOpen}
        onClose={() => {
          onFilterClose();
        }}
        start={startDate}
        end={endDate}
        onStartChange={(date) => setStartDate(date)}
        onEndChange={(date) => setEndDate(date)}
        onApply={() => {
          filterData();
          onFilterClose();
        }}
      />
    </VStack>
  );
}

export default AnalysisPage;
