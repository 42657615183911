import ENDPOINT from 'constants/endpoint';
import { IReport } from 'features/report/pages/ReportHomePage/ReportHomePage.types';
import useSwr from 'swr';
import { IResponseApi } from 'types/api';
import { accessTokenStorage } from 'utils/storage';

import { createSwrKey, defaultOptions, fetcher } from './utils';

interface IParamsReport {
  time_zone: string;
  start_date: string;
  end_date: string;
}

export function useReport(
  deviceCode: string,
  params: IParamsReport,
  options = defaultOptions,
): IResponseApi<IReport> {
  const { data, error, mutate } = useSwr<any, any>(
    createSwrKey(`${ENDPOINT.REPORT}/${deviceCode}`, { params }),
    fetcher({
      method: 'GET',
      headers: { Authorization: `bearer ${accessTokenStorage.get().value}` },
    }),
    { ...options },
  );
  return {
    data: data?.data,
    isLoading: false,
    error,
    mutate,
  };
}
