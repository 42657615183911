import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const Compass = createIcon({
  displayName: 'Compass',
  viewBox: '0 0 32 32',
  defaultProps: {
    w: '32px',
    h: '32px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M16.5 2C8.76875 2 2.5 8.26875 2.5 16C2.5 23.7313 8.76875 30 16.5 30C24.2313 30 30.5 23.7313 30.5 16C30.5 8.26875 24.2313 2 16.5 2ZM16.5 27.625C10.0813 27.625 4.875 22.4188 4.875 16C4.875 9.58125 10.0813 4.375 16.5 4.375C22.9188 4.375 28.125 9.58125 28.125 16C28.125 22.4188 22.9188 27.625 16.5 27.625ZM22.7 9.24687C22.5776 9.19905 22.447 9.17582 22.3156 9.1785C22.1843 9.18118 22.0547 9.20972 21.9344 9.2625L13.4656 12.9688L9.7625 21.4375C9.65 21.6938 9.65 21.9844 9.7625 22.2406C9.86875 22.4844 10.0656 22.675 10.3156 22.7719C10.4344 22.8188 10.5562 22.8406 10.6812 22.8406C10.8187 22.8406 10.9531 22.8125 11.0813 22.7563L19.55 19.05L23.2531 10.5813C23.3072 10.4577 23.3357 10.3244 23.337 10.1895C23.3383 10.0547 23.3123 9.92091 23.2606 9.7963C23.209 9.6717 23.1327 9.55881 23.0364 9.4644C22.94 9.36998 22.8256 9.296 22.7 9.24687ZM18.525 16.7L19.3438 17.5187L18.0187 18.8438L17.2 18.025L12.375 20.1375L14.4844 15.3125L13.6875 14.5125L15.0125 13.1875L15.8094 13.9844L20.6375 11.875L18.525 16.7ZM15.0125 13.1875L13.6875 14.5125L14.4844 15.3125L17.2 18.025L18.0187 18.8438L19.3438 17.5187L18.525 16.7L15.8125 13.9844L15.0125 13.1875Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
  ]),
});
