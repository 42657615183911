import { createIcon } from '@chakra-ui/react';
import { addKeys } from 'features/common/utils/icon.utils';
import React from 'react';

/* eslint-disable */
export const EyeClose = createIcon({
  displayName: 'EyeClose',
  viewBox: '0 0 24 24',
  defaultProps: {
    w: '24px',
    h: '24px',
    fill: 'none',
  },
  path: addKeys([
    <path
      d="M22.0822 11.3955C21.2583 9.65972 20.2778 8.24409 19.1408 7.14862L17.9483 8.34112C18.9207 9.27042 19.7678 10.4861 20.5002 12.0002C18.5502 16.0361 15.7822 17.9533 11.9994 17.9533C10.8639 17.9533 9.81808 17.7785 8.86183 17.4288L7.56972 18.7209C8.89784 19.3342 10.3744 19.6408 11.9994 19.6408C16.5041 19.6408 19.865 17.2947 22.0822 12.6025C22.1714 12.4138 22.2176 12.2077 22.2176 11.999C22.2176 11.7903 22.1714 11.5842 22.0822 11.3955ZM20.5923 3.8805L19.5932 2.88019C19.5757 2.86275 19.5551 2.84892 19.5323 2.83949C19.5095 2.83005 19.4851 2.8252 19.4605 2.8252C19.4359 2.8252 19.4115 2.83005 19.3887 2.83949C19.3659 2.84892 19.3453 2.86275 19.3278 2.88019L16.7645 5.44237C15.3512 4.7205 13.7628 4.35956 11.9994 4.35956C7.49472 4.35956 4.13378 6.70566 1.91659 11.3978C1.82747 11.5866 1.78125 11.7927 1.78125 12.0014C1.78125 12.2101 1.82747 12.4162 1.91659 12.6049C2.80237 14.4705 3.86878 15.9659 5.11581 17.091L2.63565 19.5705C2.60052 19.6057 2.58078 19.6533 2.58078 19.703C2.58078 19.7527 2.60052 19.8004 2.63565 19.8356L3.6362 20.8361C3.67136 20.8713 3.71903 20.891 3.76874 20.891C3.81845 20.891 3.86612 20.8713 3.90128 20.8361L20.5923 4.14581C20.6097 4.1284 20.6236 4.10772 20.633 4.08496C20.6424 4.06219 20.6473 4.0378 20.6473 4.01316C20.6473 3.98851 20.6424 3.96412 20.633 3.94135C20.6236 3.91859 20.6097 3.89791 20.5923 3.8805ZM3.49862 12.0002C5.45097 7.96425 8.21893 6.04706 11.9994 6.04706C13.2777 6.04706 14.44 6.26644 15.4944 6.71245L13.8467 8.36011C13.0664 7.94377 12.173 7.78926 11.2982 7.91936C10.4234 8.04946 9.61352 8.45728 8.98813 9.08267C8.36275 9.70805 7.95492 10.5179 7.82482 11.3927C7.69472 12.2675 7.84924 13.161 8.26558 13.9413L6.31042 15.8964C5.22831 14.9414 4.2955 13.6474 3.49862 12.0002ZM9.28065 12.0002C9.28106 11.5869 9.37896 11.1795 9.56638 10.8112C9.75381 10.4428 10.0255 10.1239 10.3593 9.88029C10.6932 9.63666 11.0798 9.47522 11.4877 9.40908C11.8957 9.34294 12.3135 9.37397 12.7072 9.49964L9.40511 12.8018C9.32234 12.5426 9.28035 12.2722 9.28065 12.0002Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
    <path
      d="M11.9059 14.6247C11.8248 14.6247 11.7449 14.6209 11.6657 14.6137L10.4277 15.8516C11.1723 16.1368 11.9836 16.2001 12.7634 16.0341C13.5432 15.868 14.2583 15.4796 14.822 14.9158C15.3858 14.352 15.7742 13.637 15.9403 12.8571C16.1064 12.0773 16.043 11.2661 15.7579 10.5215L14.5199 11.7595C14.5272 11.8387 14.5309 11.9186 14.5309 11.9997C14.5311 12.3445 14.4633 12.6859 14.3315 13.0044C14.1996 13.323 14.0063 13.6125 13.7625 13.8562C13.5187 14.1 13.2293 14.2934 12.9107 14.4252C12.5921 14.5571 12.2507 14.6249 11.9059 14.6247Z"
      fill="#353535"
      fillOpacity="0.6"
    />,
  ]),
});
